import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useSelector } from 'react-redux'
import UserAvatar from '../../../ui-components/UserAvatar'

const MenuCardProfile = () => {
  const userDetails = useSelector((state: any) => state?.settingReducer?.userDetails)

  return (
    <Box sx={Style.mainBox}>
      <UserAvatar
        name={userDetails?.full_name || userDetails?.email}
        profile_image={userDetails?.profile_image}
        sx={{ width: '64px', height: '64px' }}
      />
      <Typography sx={Style.name}>{userDetails?.full_name || userDetails?.email}</Typography>
    </Box>
  )
}
export default MenuCardProfile

const Style = {
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch'
  },
  name: {
    color: 'var(--text-icons-light-base-main, #323539)',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '28px',
    letterSpacing: '-0.2px'
  }
}
