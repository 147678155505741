import { useRoutes } from 'react-router-dom'
import MinimalLayout from '../layout/MinimalLayout'
import BlogPlaceHolder from 'views/extra/BlogPlaceHolder'

export default function AuthenticationRoutes() {

  const list = {
    path: '/',
    element: <MinimalLayout />,
    children: [
      // { path: '/blog', element: <BlogPlaceHolder /> }, // not needed at present
      { path: '*', element: <MinimalLayout /> }
    ]
  }
  return useRoutes([list])
}
