import React, { useState } from 'react'
import { Box, Typography, Button, Grid, useMediaQuery } from '@mui/material'
import arrow from '../../../../../assets/arrowIcon.webp'
import bankTransferIcon from '../../../../../assets/bankTransferIcon.webp'
import cryptoIcon from '../../../../../assets/cryptoIcon.webp'
import payoneerIcon from '../../../../../assets/payoneerIcon.webp'
import paypalIcon from '../../../../../assets/paypalIcon.webp'
import AuthModal from '../../../../../views/modals/AuthDialog'
const PayoutOptions = () => {
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(2)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const setStepValue = (value: any) => {
    setStep(value)
  }

  const Screen = useMediaQuery('(max-width:900px)')
  return (
    <Box sx={Style.mainBox}>
      <Grid sx={Style.innerMainBox} container>
        <Grid sx={Style.contentBox} item xs={12} md={5}>
          <Box sx={Style.innerContentBox}>
            <Typography sx={Style.contentHeading}>You’ve earned it</Typography>
            <Box sx={Style.descriptionAndParaBox}>
              <Typography sx={Style.description}>Flexible Payout Options</Typography>
              <Typography sx={Style.para}>
                It’s only right that payout options are easy and flexible, after all, you’ve worked hard for it. Whether
                you prefer Bank Transfers, Paypal, Crypto or Payoneer, we’ve got you covered.
              </Typography>
            </Box>
          </Box>
          {Screen ? null : (
            <Button
              sx={Style.buttonStyle}
              variant="contained"
              className="payoutOptions-signUpButton"
              size="large"
              color="primary"
              endIcon={<img src={arrow} alt="externalLinkIcon" />}
              onClick={(props) => {
                setStep(2)
                handleClickOpen()
              }}
            >
              Sign Up
            </Button>
          )}
        </Grid>
        <Grid sx={Style.mainCardBox} item xs={12} md={7}>
          <Box sx={Style.innerCardBox}>
            <Box sx={Style.wrapperBox}>
              <Box sx={Style.imageBox}>
                <img src={bankTransferIcon} alt="bankTransferIcon" />
              </Box>
              <Box sx={Style.imageBox}>
                <img src={paypalIcon} alt="paypalIcon" />
              </Box>
            </Box>
            <Box sx={Style.wrapperBox}>
              <Box sx={Style.imageBox}>
                <img src={payoneerIcon} alt="payoneerIcon" />
              </Box>
              <Box sx={Style.imageBox}>
                <img src={cryptoIcon} alt="cryptoIcon" />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {Screen ? (
        <Button
          sx={Style.buttonStyle}
          variant="contained"
          className="payoutOptions-signUpButton"
          size="large"
          color="primary"
          endIcon={<img src={arrow} alt="externalLinkIcon" />}
          onClick={(props) => {
            setStep(2)
            handleClickOpen()
          }}
        >
          Sign Up
        </Button>
      ) : null}
      {open && <AuthModal open={open} handleClose={handleClose} step={step} setStepValue={setStepValue} />}
    </Box>
  )
}
const Style = {
  mainBox: {
    display: 'flex',
    padding: { xs: '60px 16px 40px 16px', md: '64px 112px' },
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--main-system-64-px, 64px)',
    background: 'var(--base-white, #FFF)'
  },
  innerMainBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    gap: { xs: '60px', md: '0px' },
    margin: 'auto'
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '40px'
  },
  contentHeading: {
    color: 'var(--text-icons-light-primary, #2D146F)',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.14px',
    textTransform: 'uppercase'
  },
  innerContentBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: { xs: 'center', md: 'flex-start' },
    gap: '8px',
    alignSelf: 'stretch'
  },
  descriptionAndParaBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: { xs: 'center', md: 'flex-start' },
    gap: '20px',
    alignSelf: 'stretch'
  },
  description: {
    textAlign: { xs: 'center', md: 'left' },
    color: 'var(--text-icons-light-base-main, #323539)',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: '-0.48px',
    alignSelf: 'stretch'
  },
  para: {
    textAlign: { xs: 'center', md: 'left' },
    color: 'var(--text-icons-light-base-second, #858C95)',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.16px',
    alignSelf: 'stretch'
  },
  buttonStyle: {
    width: '236px',
    height: '54px',
    fontSize: '17px',
    ':hover': {
      boxShadow: '3px 3px 10px 1px rgba(0, 13, 185, 0.6)'
    }
  },
  mainCardBox: {
    display: 'flex',
    width: '520px',
    padding: { xs: 0, md: '40px' },
    justifyContent: { xs: 'center' },
    alignItems: { xs: 'center' },
    flex: '1 0 0',
    gap: '16px',
    borderRadius: '20px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.04)'
  },
  innerCardBox: {
    display: 'flex',
    width: '343px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    flexShrink: 0,
    alignSelf: 'stretch',
    borderRadius: '20px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.04)'
  },
  wrapperBox: {
    display: 'flex',
    height: '318px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    flex: '1 0 0'
  },
  imageBox: {
    display: 'flex',
    padding: '16px 20px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    flex: '1 0 0',
    alignSelf: 'stretch',
    borderRadius: '10px',
    border: '1px solid var(--stroke-light-base, #E5E5E7)',
    background: 'var(--base-white, #FFF)',
    boxShadow: '0px 4px 8px 0px rgba(113, 128, 150, 0.08), 0px 0px 1px 0px rgba(113, 128, 150, 0.04)'
  }
}
export default PayoutOptions
