import React, { useState } from 'react'
import { Box, Typography, Button, Grid, useMediaQuery } from '@mui/material'
import arrow from '../../../../../assets/arrowIcon.webp'
import sliceImage from '../../../../../assets/sliceImage.webp'
import doubleLineImage from '../../../../../assets/doubleLineImage.webp'
import greyBoxImage from '../../../../../assets/greyBoxImage.webp'
import AuthModal from '../../../../../views/modals/AuthDialog'
const ExistingNetwork = () => {
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(2)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const setStepValue = (value: any) => {
    setStep(value)
  }

  const Screen = useMediaQuery('(max-width:900px)')
  return (
    <Box sx={Style.mainBox}>
      <Grid sx={Style.innerMainBox} container>
        <Grid sx={Style.mainCardBox} item xs={12} md={7} order={{ xs: 2, md: 1 }}>
          <Box sx={Style.innerCardBox}>
            <Box sx={Style.wrapperBox}>
              <Box sx={Style.overviewBox}>
                <Box sx={Style.headingBox}>
                  <Typography sx={Style.heading}>Monthly Overview</Typography>
                  <Typography sx={Style.money}>$500.35 </Typography>
                </Box>
                <Box style={{ position: 'relative', width: '117px', height: '118px' }}>
                  <img src={sliceImage} alt="sliceImage" width="100%" height="100%" />
                  <Box sx={Style.textOverlay}>
                    <Typography sx={Style.sliceImageStyle}>99%</Typography>
                    <Typography sx={Style.fillRateStyle}>Fill Rate</Typography>
                  </Box>
                </Box>
                <Box style={{ width: ' 98.1px', height: ' 11.7px' }}>
                  <img src={greyBoxImage} alt="greyBox" width="100%" height="100%" />
                </Box>
                <Box style={{ width: '156.222px', height: '36.703px' }}>
                  <img src={doubleLineImage} width="100%" height="100%" alt="doubleLineImage" />
                </Box>
              </Box>
              <Box sx={Style.statsBox}>
                <Box sx={{ ...Style.card, gap: '8px' }}>
                  <Typography sx={Style.cardHeadings}>Latest Stats</Typography>
                  <Typography sx={Style.cardStats}>225.6</Typography>
                  <Typography sx={Style.growthStyle}>+2% monthly growth</Typography>
                </Box>
                <Box sx={Style.card}>
                  <Typography sx={Style.cardHeadings}>Impressions</Typography>
                  <Typography sx={Style.cardStats}>45.8k</Typography>
                </Box>
                <Box sx={Style.card}>
                  <Typography sx={Style.cardHeadings}>Number of Ad Units</Typography>
                  <Typography sx={Style.cardStats}>5</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid sx={Style.contentBox} item xs={12} md={5} order={{ xs: 1, md: 2 }}>
          <Box sx={Style.innerContentBox}>
            <Typography sx={Style.contentHeading}>100% INCREMENTAL REVENUE</Typography>
            <Box sx={Style.descriptionAndParaBox}>
              <Typography sx={Style.description}>Keep Your Existing Networks</Typography>
              <Typography sx={Style.para}>
                Choose the units you need, and leave those you don’t. Your website is unique and that's why DOT has been
                engineered with customization at its core. It’s your website, you’re in control.
              </Typography>
            </Box>
          </Box>
          {Screen ? null : (
            <Button
              sx={Style.buttonStyle}
              className="existingNetwork-signUpButton"
              variant="contained"
              size="large"
              color="primary"
              endIcon={<img src={arrow} alt="externalLinkIcon" />}
              onClick={(props) => {
                setStep(2)
                handleClickOpen()
              }}
            >
              Sign Up
            </Button>
          )}
        </Grid>
      </Grid>
      {Screen ? (
        <Button
          sx={Style.buttonStyle}
          variant="contained"
          className="existingNetwork-signUpButton"
          size="large"
          color="primary"
          endIcon={<img src={arrow} alt="externalLinkIcon" />}
          onClick={(props) => {
            setStep(2)
            handleClickOpen()
          }}
        >
          Sign Up
        </Button>
      ) : null}
      {open && <AuthModal open={open} handleClose={handleClose} step={step} setStepValue={setStepValue} />}
    </Box>
  )
}
const Style = {
  mainBox: {
    display: 'flex',
    // width: "var(--device-desktop, 1440px)",
    // height: "530px",
    padding: { xs: '40px 16px', md: '64px 112px' },
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--main-system-64-px, 64px)',
    background: 'var(--base-white, #FFF)'
  },
  innerMainBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: { xs: '60px', md: '0px' },
    alignSelf: 'stretch',
    margin: 'auto'
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '40px'
    // flex: "1 0 0"
  },
  contentHeading: {
    color: 'var(--text-icons-light-primary, #2D146F)',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.14px',
    textTransform: 'uppercase'
  },
  innerContentBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: { xs: 'center', md: 'flex-start' },
    gap: '8px',
    alignSelf: 'stretch'
  },
  descriptionAndParaBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: { xs: 'center', md: 'flex-start' },
    gap: '20px',
    alignSelf: 'stretch'
  },
  description: {
    textAlign: { xs: 'center', md: 'left' },
    color: 'var(--text-icons-light-base-main, #323539)',

    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: '-0.48px',
    alignSelf: 'stretch'
  },
  para: {
    textAlign: { xs: 'center', md: 'left' },
    color: 'var(--text-icons-light-base-second, #858C95)',

    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.16px',
    alignSelf: 'stretch'
  },
  buttonStyle: {
    width: '236px',
    height: '54px',
    fontSize: '17px',
    ':hover': {
      boxShadow: '3px 3px 10px 1px rgba(0, 13, 185, 0.6)'
    }
  },
  mainCardBox: {
    display: 'flex',
    justifyContent: { xs: 'center' },
    alignItems: { xs: 'center' },
    flex: '1 0 0'
  },
  innerCardBox: {
    display: 'flex',
    width: { xs: '100%', sm: '486px' },
    margin: 'auto',
    padding: { xs: 0, md: '36px' },
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '28.8px',
    borderRadius: '18px',
    boxShadow: '0px 0.9px 1.8px 0px rgba(16, 24, 40, 0.04)'
  },
  wrapperBox: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '14.4px',
    alignSelf: 'stretch'
  },
  overviewBox: {
    display: 'flex',
    padding: '21.6px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '1 0 0',
    alignSelf: 'stretch',
    borderRadius: '9px',
    border: '0.9px solid var(--stroke-light-base, #E5E5E7)',
    background: '#FFF',
    boxShadow: '0px 4px 8px 0px rgba(113, 128, 150, 0.08), 0px 0px 1px 0px rgba(113, 128, 150, 0.04)'
  },
  headingBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '3.6px',
    alignSelf: 'stretch'
  },
  heading: {
    color: 'var(--text-icons-light-base-second, #858C95)',

    fontSize: '12.6px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '-0.09px'
  },
  money: {
    color: 'var(--base-black, #000)',

    fontSize: '21.6px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28.8px',
    letterSpacing: '-0.432px',
    width: '140.358px',
    height: '31.056px'
  },
  sliceImageStyle: {
    width: '71.523px',
    height: '31.056px',
    flexShrink: 0,
    color: 'var(--base-black, #000)',
    textAlign: 'center',

    fontSize: '21.6px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28.8px',
    letterSpacing: '-0.432px'
  },
  fillRateStyle: {
    width: '75.288px',
    height: '16.94px',
    flexShrink: 0,
    color: 'var(--text-icons-light-base-second, #858C95)',
    textAlign: 'center',

    fontSize: '12.6px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '-0.09px'
  },
  textOverlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  statsBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '14.4px',
    flex: '1 0 0',
    alignSelf: 'stretch'
  },
  card: {
    display: 'flex',
    padding: '16px 20px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: 'var(--roundness-round-outside, 12px)',
    border: '1px solid var(--stroke-light-base, #E5E5E7)',
    background: 'var(--background-light-base-main, #FFF)',
    boxShadow: '0px 4px 8px 0px rgba(113, 128, 150, 0.08), 0px 0px 1px 0px rgba(113, 128, 150, 0.04)'
  },
  cardHeadings: {
    color: 'var(--text-icons-light-base-second, #858C95)',

    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.1px',
    alignSelf: 'stretch'
  },
  cardStats: {
    color: 'var(--text-icons-light-base-main, #323539)',

    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '38px',
    letterSpacing: '-0.42px',
    alignSelf: 'stretch'
  },
  growthStyle: {
    color: 'var(--text-icons-light-primary, #2D146F)',

    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.1px'
  }
}
export default ExistingNetwork
