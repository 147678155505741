declare global {
    interface Window {
        fbq: any
    }
}

const sendFbPixelEvents = process.env.REACT_APP_SEND_FB_PIXEL_EVENTS === 'true' ? true : false

export const sendNewPublisherRegisteredEventToFbPixel = (data: any) => {
    if (sendFbPixelEvents && typeof window.fbq === 'function') {
        window.fbq('track', 'publisherRegistered', { email: data?.userEmail })
    }
}

export const sendNewWebsiteSubmitedEventToFbPixel = (data: any) => {
    if (sendFbPixelEvents && typeof window.fbq === 'function') {
        window.fbq('track', 'newWebsiteSubmited', { domain_name: data?.domain_name })
    }
}