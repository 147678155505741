export const steps = [
    {
        title: 'Welcome',
        description: 'Highlights in under 1 minute',
        completed: false
    },
    {
        title: 'Get Personalised Support',
        description: 'Connect with us on Telegram',
        completed: false
    },
    {
        title: 'Add Website',
        description: 'Let us know the basics',
        completed: false
    },
    {
        title: 'Submit Analytics',
        description: 'Authenticate Your Website Traffic',
        completed: false
    },
    {
        title: 'All Done!',
        description: 'We are reviewing your submission',
        completed: false
    }
]