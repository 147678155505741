import { Box } from '@mui/material'
import telegramIcon from '../../../../../assets/telegramIcon.webp'
import linkedinIcon from '../../../../../assets/linkedinIcon.webp'
import twitterIcon from '../../../../../assets/twitterIcon.webp'
import { redirectToNewPage } from '../../../../../util/redirect'
import { SOCIALS } from '../../../../../constants/redirectLinks'
export default function CopyRight() {
  return (
    <Box sx={Style.mainBox}>
      <Box sx={Style.contentStyle}>
        <span>Engineered with ❤️ in London, UK 🇬🇧 © 2024 DOT. All Rights Reserved. ✨</span>
      </Box>
      <Box sx={Style.iconContainer}>
        <Box sx={Style.icon} onClick={() => redirectToNewPage(SOCIALS.LINKEDIN)}>
          <img src={linkedinIcon} alt="linkedinIcon" />
        </Box>
        <Box sx={Style.icon} onClick={() => redirectToNewPage(SOCIALS.TELEGRAM)}>
          <img src={telegramIcon} alt="telegramIcon" />
        </Box>
        <Box sx={Style.icon} onClick={() => redirectToNewPage(SOCIALS.TWITTER)}>
          <img src={twitterIcon} alt="twitterIcon" />
        </Box>
      </Box>
    </Box>
  )
}
const Style = {
  mainBox: {
    display: 'flex',
    paddingTop: '24px',
    justifyContent: { xs: 'center', md: 'space-between' },
    alignItems: 'center',
    alignSelf: 'stretch'
  },
  iconContainer: {
    display: { xs: 'none', md: 'flex' },
    alignItems: 'flex-start',
    gap: '24px'
  },
  icon: {
    cursor: 'pointer'
  },
  contentStyle: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    color: 'var(--text-icons-light-base-second, #858C95)',
    textAlign: 'center',

    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.16px'
  }
}
