import { Box, Typography } from '@mui/material'
import { InputAdornment, TextField } from '@mui/material'

const Step3 = ({ setWebsiteData, validateUrl, websiteData }: any) => {
  return (
    <Box sx={Style.mainBox}>
      <Box sx={Style.headingBox}>
        <Typography sx={Style.step}>step 3</Typography>
        <Typography sx={Style.title}>Add Website</Typography>
        <Typography sx={Style.description}>Let us know the basics</Typography>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Typography sx={Style.websiteDomain}>Add Website Domain</Typography>
        <TextField
          sx={{ width: '100%' }}
          placeholder="Enter URL"
          value={websiteData.name}
          onChange={(e) => {
            setWebsiteData({ name: e.target.value })
            validateUrl(e.target.value)
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography sx={Style.http}>https://</Typography>
              </InputAdornment>
            )
          }}
        />
      </Box>
    </Box>
  )
}
const Style = {
  mainBox: {
    display: 'flex',
    padding: '32px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch'
  },
  headingBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch'
  },
  step: {
    color: 'var(--text-icons-light-base-second, #858C95)',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.12px',
    textTransform: 'uppercase'
  },
  title: {
    color: 'var(--text-icons-light-base-main, #323539)',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: '-0.2px'
  },
  description: {
    color: 'var(--text-icons-light-base-second, #858C95)',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.16px'
  },
  websiteBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch'
  },
  websiteDomain: {
    color: 'var(--text-icons-light-base-main, #323539)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.1px',
    alignSelf: 'stretch'
  },
  http: {
    color: 'var(--text-icons-light-base-main, #323539)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px'
  }
}
export default Step3
