export const getUrl = () => {
    return window.location.href;
}

export const getUtmValues = (url: string) => {
    const urlObj = new URL(url);
    const searchParams = urlObj.searchParams;
    
    const utm_source = searchParams.get('utm_source') || "";
    const utm_medium = searchParams.get('utm_medium') || "";
    const utm_campaign = searchParams.get('utm_campaign') || "";
    
    return {
      utm_source,
      utm_medium,
      utm_campaign,
    };
};

export const createRedirectUrl = (emailAddress:string, redirectUrlBase:string) => {
  const encodedEmail = encodeURIComponent(emailAddress);
  const redirectUrl = `${redirectUrlBase}${encodedEmail}`;
  return redirectUrl;
}