import { Box } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import './App.scss'
import AdminRoutes from './routes/AdminRoutes'
import AuthenticationRoutes from './routes/AuthenticationRoutes'
import MainRoutes from './routes/MainRoutes'
import mixpanel from 'mixpanel-browser';

// defaultTheme
import themes from './themes'
import { UserType } from './util/constants'
import Cookies from 'js-cookie'
import { userDetails } from './store/actions/setting'
import SnackbarOrigin from './layout/ui-components/SnackbarOrigin'
import Onboarding from './layout/ui-components/onboarding-flow'
import { config } from 'services/mixpanel/config'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { CLIENT_ID } from 'services/googleOneTapLogin/config'


mixpanel.init(config.project_token, config.project_config);

function App() {
  const dispatch: any = useDispatch()
  const user: any = useSelector((state: any) => state.authReducer)
  const [loading, setLoading] = useState(true)
  const client = useSelector((state: any) => state.apolloClient.client)

  useEffect(() => {
    if (Cookies.get('dot-pub-token')) {
      dispatch(userDetails(client))
    }
    // eslint-disable-next-line
  }, [])

  function fakeRequest() {
    return new Promise((resolve) => setTimeout(() => resolve(1), 50))
  }

  useEffect(() => {
    fakeRequest().then(() => {
      const el = document.querySelector('.loader-container')
      if (el) {
        el.remove()
        setLoading(!loading)
      }
    })
    // eslint-disable-next-line
  }, [])
  return (
    <ThemeProvider theme={themes()}>
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <BrowserRouter basename="/">
          <Box>
            <SnackbarOrigin />
            {/* <MainRoutes /> */}
            {user?.isLoggedIn ? <AppContent user={user} /> : <AuthenticationRoutes />}
          </Box>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </ThemeProvider>
  )
}

function AppContent({ user }: any) {
  // if (user?.isNewUser === false && user?.userType === UserType.CUSTOMER) {
  if (user?.isNewUser === true && user?.userType === UserType.CUSTOMER) {
    return <Onboarding />
  }

  return <Box>{user?.userType === UserType.ADMIN ? <AdminRoutes /> : <MainRoutes />}</Box>
}

export default App
