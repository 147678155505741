import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Typography } from '@mui/material'
import Box from '@mui/material/Box'
// import { useTheme } from '@mui/material/styles'
import { useDispatch } from 'react-redux'
import { LoginAuthProvider } from '../../types/auth'
import SignUpForm from './SignUpForm'
import GoogleSignUpButton from './authbuttons/GoogleSignUpButton'
import FacebookSignUpButton from './authbuttons/FacebookSignUpButton'
import { signup } from '../../store/actions/auth'
import { signUpWithEmail } from '../../store/actions/auth'
import { signup_initiated } from 'services/mixpanel'

export default function Login(props: any) {
  const { handleClose, step, setStepValue } = props
  const dispatch = useDispatch()

  const handleSignUp = async (provider: LoginAuthProvider, args?: { email: string; password: string }) => {
    if (provider === LoginAuthProvider.Email) {
      dispatch(signUpWithEmail(args, setStepValue) as any)
    }
    else {
      dispatch(signup(provider, args) as any)
    }
    signup_initiated({ signup_mode: provider })

  }

  // const theme = useTheme()

  return (
    <Box id="signUpDialog">
      <Box
        className="modal-header"
        sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', padding: '20px' }}
      >
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Typography variant="h3" sx={{ fontSize: '36px' }}>
            Sign Up
          </Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ p: '20px' }}>
        <GoogleSignUpButton handleClose={handleClose} handleSignUp={handleSignUp} setStepValue={setStepValue} />
        {/* <FacebookSignUpButton
          handleClose={handleClose}
          handleSignUp={handleSignUp}
          setStepValue={setStepValue}
          step={step}
        /> */}
      </Box>
      <Box sx={Style.suggestionBox}>
        <Box sx={Style.line}></Box>
        <Box sx={Style.suggestion}>Or sign up with your email</Box>
        <Box sx={Style.line}></Box>
      </Box>
      <SignUpForm step={step} handleSignUp={handleSignUp} setStepValue={setStepValue} />
    </Box>
  )
}
const Style = {
  suggestionBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    alignSelf: "stretch",
    mb: 2
  },
  suggestion: {
    color: "var(--gray-700, var(--gray-700, #323539))",

    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "-0.16px"
  },
  line: {
    height: "1px",
    width: { xs: "13%", sm: "22%", md: "25%" },
    background: 'var(--neutral-600, #EAEBF0)'
  }
}
