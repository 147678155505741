import { Helmet } from 'react-helmet';

const CalendlyHelmet = () => {
  return (
    <Helmet>
      <link
        href="https://assets.calendly.com/assets/external/widget.css"
        rel="stylesheet"
      />
      <script
        src="https://assets.calendly.com/assets/external/widget.js"
        type="text/javascript"
        async
      ></script>
    </Helmet>
  );
};

export default CalendlyHelmet;