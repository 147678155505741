import { gql } from '@apollo/client'

const getPublisherList = gql`
query ($skip: Float, $limit: Float,$user_id: String,$columnName: String, $order: String,$status: [String!], $search: String) {
  viewWebsiteList(skip: $skip, limit: $limit,user_id: $user_id, columnName: $columnName,order: $order,status: $status, search: $search) {
    websites {
      _id
      user_id
      name
      screen_shot_url
      status
      commission
      created_at
      updated_at
      analytics
    }
    page
    limit
    total_records
  }
}
`
export const getPublisherDetails = gql`
query viewWebsiteDetailsList($skip: Float, $limit: Float,$user_id: String,$columnName: String, $order: String,$status: [String!], $search: String) {
  viewWebsiteDetailsList(skip: $skip, limit: $limit,user_id: $user_id, columnName: $columnName,order: $order,status: $status, search: $search) {
    websites {
      _id
      user_id
      name
      screen_shot_url
      status
      commission
      created_at
      updated_at
      analytics
    }
    page
    limit
    total_records
  }
}
`
export const getTotalWebsiteCount = gql`
  query getTotalWebsiteCount {
    getTotalWebsiteCount {
      activeCount
      rejectedCount
      pendingCount
      inprogressCount
    }
  }
`
export const deleteUser = gql`
mutation deleteUser($user_id: ID!) {
  deleteUser(user_id: $user_id) {
    _id
    full_name
    email
    user_type
  }
}
`
export const editWebsite = gql`
  mutation editWebsite(
    $websiteId: String!
    $name: String
    $screen_shot_url: String
    $status: String
    $commission: Float
  ) {
    editWebsite(
      websiteId: $websiteId
      name: $name
      screen_shot_url: $screen_shot_url
      status: $status
      commission: $commission
    ) {
      _id
      name
      screen_shot_url
      status
      commission
    }
  }
`;

export const editProfile = gql`
  mutation editProfile(
    $userId: String
    $full_name: String
    $email: String
    $password: String
    $user_type: String
    $profile_image: String
    $status: String
    $social_handler: String
    $phone_number: String
    $commission: Float
    $new_user: Boolean
    $address: JSON
    $business_name: String
  ) {
    editProfile(
      userId: $userId
      full_name: $full_name
      email: $email
      password: $password
      user_type: $user_type
      profile_image: $profile_image
      status: $status
      social_handler: $social_handler
      phone_number: $phone_number
      commission: $commission
      new_user: $new_user
      address: $address
      business_name: $business_name
    ) {
      _id
      full_name
      email
      user_type
    }
  }
`;

export { getPublisherList }