import { Box, Typography, Grid, useMediaQuery, Button } from '@mui/material'
import konisGroupImage from '../../../../assets/lcs_mobile_laptop.png'
import bussinessCloudImage from '../../../../assets/lcs_mobile.png'
import cryptoWidgetsImage from '../../../../assets/lcs_laptop.png'
import rightArrowIcon from '../../../../assets/rightArrowICon.webp'
import externalLinkIcon from '../../../../assets/externalLinkIcon.webp'
import { CASE_STUDIES } from '../../../../constants/redirectLinks'
import { redirectToNewPage } from '../../../../util/redirect'
const Details = [
  {
    id: 1,
    image: konisGroupImage,
    heading: 'DOT x Konis Group',
    description: 'A Portfolio of Leading Crypto & Tech News Websites',
    redirect: CASE_STUDIES.KONIS
  },
  {
    id: 2,
    image: bussinessCloudImage,
    heading: 'DOT x Business Cloud',
    description: 'The Premier UK Based Business & Innovation News Website',
    redirect: CASE_STUDIES.BUSINESS_CLOUD
  },
  {
    id: 3,
    image: cryptoWidgetsImage,
    heading: 'DOT x CryptoWidgets',
    description: 'Token Price Tracking for Crypto Enthusiasts & Investors',
    redirect: CASE_STUDIES.CRYPTO
  }
]

const LatestCaseStudies = () => {
  // const handleClickCaseStudies = (caseStudy: string) => {}

  const Screen = useMediaQuery('(max-width:900px)')
  const xsDescription =
    'Here’s a quick glance over our latest blog posts and media articles written by our team members, staff and guest writers.'
  const mdDescription = 'Let the numbers do the talking, zero fluff. '
  return (
    <Box sx={Style.mainBox}>
      <Box sx={Style.headingBox}>
        <Typography sx={Style.mainHeading}>Latest Case Studies</Typography>
        <Typography sx={Style.mainDescription}>{Screen ? xsDescription : mdDescription}</Typography>
      </Box>
      <Grid container sx={Style.containerBox}>
        {Details.map((detail, index) => (
          <Grid item xs={12} md={3.75} sx={Style.itemBox} key={index}>
            <Box sx={Style.imageBox}>
              <img src={detail.image} alt="dotImage" width="100%" height="auto" />
            </Box>
            <Box sx={Style.descriptionBox}>
              <Box sx={Style.headingAndDescriptionBox}>
                <Typography sx={Style.heading}>{detail.heading}</Typography>
                <Typography sx={Style.description}>{detail.description}</Typography>
              </Box>
              <Button sx={Style.buttonBox} onClick={() => redirectToNewPage(detail?.redirect)}>
                <Typography sx={Style.buttonTypography}>Read More</Typography>
                <Box sx={Style.buttonIconBox}>
                  <img src={rightArrowIcon} alt="arrowLogo" />
                </Box>
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box sx={Style.footer}>
        <Button
          sx={Style.buttonStyle}
          variant="contained"
          size="large"
          color="primary"
          endIcon={<img src={externalLinkIcon} alt="externalLinkIcon" />}
          onClick={() => redirectToNewPage(CASE_STUDIES.HOME)}
        >
          View All
        </Button>
      </Box>
    </Box>
  )
}
const Style = {
  mainBox: {
    display: 'flex',
    padding: { xs: '16px', md: '64px 122px' },
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: { xs: '32px', md: '64px' },
    background: 'var(--background-light-base-main, #FFF)'
  },
  headingBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    alignSelf: 'stretch'
  },
  mainHeading: {
    alignSelf: 'stretch',
    color: 'var(--text-icons-light-base-main, #323539)',
    textAlign: 'center',
    fontSize: { xs: '24px', md: '32px' },
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: { xs: '32px', md: '40px' },
    letterSpacing: '-0.48px'
  },
  mainDescription: {
    alignSelf: 'stretch',
    color: 'var(--text-icons-light-base-second, #858C95)',
    textAlign: 'center',
    fontSize: { xs: '16px', md: '18px' },
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: { xs: '24px', md: '26px' },
    letterSpacing: '-0.18px'
  },
  containerBox: {
    display: 'flex',
    width: '100%',
    flexWrap: 'nowrap',
    flexDirection: { xs: 'column', md: 'row' },
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: { xs: '32px', md: 0 }
  },
  itemBox: {
    display: 'flex',
    flex: 1,
    // minWidth: { xs: '', md: '390px' },
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    border: '.1px solid #a5a5a6',
    borderRadius: '10px'
  },
  imageBox: {
    display: 'flex',
    height: '250px',
    width: '100%',
    padding: { xs: '25%', md: '20%', lg: '30%' },
    // padding: '32.919px 100px 33.081px 100px',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    background: 'var(--text-icons-dark-base-main, #F9F9F9)',
    boxShadow: '0px 4px 8px 0px rgba(113, 128, 150, 0.08), 0px 0px 1px 0px rgba(113, 128, 150, 0.04)'
  },
  descriptionBox: {
    display: 'flex',
    padding: '24px 32px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch',
    boxShadow: '0px 4px 8px 0px rgba(113, 128, 150, 0.08), 0px 0px 1px 0px rgba(113, 128, 150, 0.04)'
  },
  headingAndDescriptionBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch'
  },
  heading: {
    alignSelf: 'stretch',
    color: 'var(--text-icons-light-base-main, #323539)',

    fontSize: { xs: '18px', md: '22px' },
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: { xs: '24px', md: '30px' },
    letterSpacing: '-0.22px'
  },
  description: {
    height: 'var(--main-system-48-px, 48px)',
    alignSelf: 'stretch',
    color: 'var(--text-icons-light-base-second, #858C95)',

    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.16px'
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px',
    borderRadius: '5px',
    cursor: 'pointer',
    '&hover': {
      cursor: 'pointer'
    }
  },
  buttonTypography: {
    color: 'var(--background-light-primary-main, #5828C4)',

    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px'
  },
  buttonIconBox: {
    width: '20px',
    height: '20px'
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    alignSelf: 'stretch'
  },
  buttonStyle: {
    width: '135px',
    height: '54px',
    fontSize: '17px',
    ':hover': {
      boxShadow: '3px 3px 10px 1px rgba(0, 13, 185, 0.6)'
    }
  }
}
export default LatestCaseStudies
