import { gql } from '@apollo/client'

const queryLogin = gql`
  query login($email: String!) {
    login(email: $email) {
      _id
      full_name
      email
      user_type
      profile_image
      status
      social_handler
      phone_number
      new_user
      fuid
      commission
      _token
    }
  }
`


const mutationSignUp = gql`
  mutation signup(
    $full_name: String
    $fuid: String!
    $profile_image: String
    $email: String!
    $password: String
  ) {
    signup(
      full_name: $full_name
      fuid: $fuid
      profile_image: $profile_image
      email: $email
      password: $password
    ) {
        _id
        full_name
        email
        user_type
        profile_image
        status
        new_user
        fuid
        commission
        _token
    }
  }
`;

export { queryLogin, mutationSignUp }
