import { NOTIFICATIONS } from '../../util/notification'
import { DATA_LOADING_END, DATA_LOADING_START, ERROR_MSG, SUCCESS_MSG } from '../../store/actionTypes'
import { WARNING_MSG } from '../../store/actionTypes'
import { logoutUser } from '../../store/actions/auth'

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU'
export const MENU_TOGGLE = '@customization/MENU_TOGGLE'
export const MENU_OPEN = '@customization/MENU_OPEN'

export const loadingStart = () => {
  return (dispatch: any) => {
    dispatch({
      type: DATA_LOADING_START
    })
  }
}

export const loadingStop = () => {
  return (dispatch: any) => {
    dispatch({
      type: DATA_LOADING_END
    })
  }
}

// fail notification
export const notificationFail = (err: any, errorMessage?: string) => {
  return (dispatch: any) => {
    let msg = NOTIFICATIONS.ERROR.someThingWrong
    if (errorMessage) {
      msg = errorMessage
    }
    if (err.graphQLErrors && err.graphQLErrors.length > 0) {
      msg = err.graphQLErrors[0].message
      let code = err.graphQLErrors[0].extensions?.code
      let statusCode = err.graphQLErrors[0].extensions?.statusCode
      if (code === 'Unauthorized' && statusCode === 401) {
        msg = err.graphQLErrors[0].message
        dispatch(logoutUser())
      } else {
        msg = err.graphQLErrors[0].message
      }
    } else if (err.message) {
      msg = err.message
    }
    dispatch({
      type: ERROR_MSG,
      data: msg
    })
  }
}

export const notificationSuccess = (message: string) => {
  return (dispatch: any) => {
    dispatch({
      type: SUCCESS_MSG,
      data: message
    })
  }
}
export const notificationWarning = (message: string) => {
  return (dispatch: any) => {
    dispatch({
      type: WARNING_MSG,
      data: message
    })
  }
}
