export const BOOK_DEMO = "https://calendly.com/dotaudiences/30min"

export const BOOK_DEMO_POPUP_HIDE_DETAILS = "https://calendly.com/dotaudiences/30min?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=2d146f"

// export const BOOK_DEMO_POPUP = "https://calendly.com/dotaudiences/30min?hide_event_type_details=0&hide_gdpr_banner=1&primary_color=2d146f"
export const BOOK_DEMO_POPUP = "https://calendly.com/d/cn6z-m98-3rr/publisher-introduction-to-dot"

export const LIVE_CHAT = "https://t.me/DotAds_Bot?start=64d0f2cf2f590d0625058864"

export const SUPPORT_GOOGLE_AD_SENSE = "https://support.google.com/adsense/answer/10502938?hl=enGB&visit_id=638307129496140469-1228929756&rd=2"

export const CASE_STUDIES = {
    HOME: `https://dotaudiences.com/blog/case-studies`,
    KONIS: `https://dotaudiences.com/blog/case-study/publisher/case-study-konis-technology/`,
    BUSINESS_CLOUD: `https://dotaudiences.com/blog/case-study/publisher/case-study-business-cloud`,
    CRYPTO: `https://dotaudiences.com/blog/case-study/casestudy-cryptowidgets/`
}

export const HELP_DOT_AUDIENCES = `https://help.dotaudiences.com/category/publishers`

export const TERMS_OF_SERVICE = `https://dotaudiences.com/publisher-terms-of-service`

export const PRIVACY_POLICY = `https://dotaudiences.com/privacy-policy`

export const DOT_ADS = `https://ads.dotaudiences.com`

export const DOT_PR_MARKETPLACE = `https://marketplace.dotaudiences.com`

export const SOCIALS = {
    LINKEDIN : `https://www.linkedin.com/company/dot-audiences/`,
    TELEGRAM : `https://tg.pulse.is/DOT_Publishers_Bot?start=65a7c746102453a3860f13cb`,
    TWITTER  : `https://twitter.com/dotaudiences`
}

export const DOT_REACH_OUT_MAIL = 'hello@dotaudiences.com'

export const DOT_TUTORIAL_VIDEO_URL = "https://www.youtube-nocookie.com/embed/p0oSkICEeOQ?rel=0&controls=0"

