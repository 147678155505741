import { gql } from '@apollo/client'

const queryUserDetails = gql`
  query userDetails($user_id: String) {
    userDetails(user_id: $user_id) {
      _id
      full_name
      email
      user_type
      profile_image
      status
      fuid
      commission
      address
      social_handler
      business_name
      _token
      created_at
      updated_at
      phone_number
      is_deleted
      new_user
    }
  }
`

const queryUpdate = gql`
  mutation editProfile(
    $userId: String
    $full_name: String
    $email: String
    $password: String
    $user_type: String
    $profile_image: String
    $status: String
    $social_handler: String
    $phone_number: String
    $commission: Float
    $new_user: Boolean
    $address: JSON
    $business_name: String
  ) {
    editProfile(
      userId: $userId
      full_name: $full_name
      email: $email
      password: $password
      user_type: $user_type
      profile_image: $profile_image
      status: $status
      social_handler: $social_handler
      phone_number: $phone_number
      commission: $commission
      new_user: $new_user
      address: $address
      business_name: $business_name
    ) {
      _id
      full_name
      email
      user_type
      profile_image
      status
      social_handler
      address
      phone_number
      commission
      new_user
      fuid
      _token
      created_at
      updated_at
    }
  }
`;



export { queryUserDetails, queryUpdate }
