// Sections
import { Box } from '@mui/material'
import Footer from './components/Footer'
import TopNavbar from './components/Nav/TopNavbar'
import Features from './components/Sections/Features'
import Header from './components/Sections/Header'
import Testimonials from './components/Sections/Testimonials'
import LogoSection from './components/Sections/LogoSection'
import LatestCaseStudies from './components/Sections/LatestCaseStudies'
import FrequentlyAskedQuestions from './components/Sections/FrequentlyAskedQuestions'
import Potential from './components/Sections/Potential'
import { page_viewed } from 'services/mixpanel'
import { getUrl, getUtmValues } from 'util/helperFunctions'

export default function Landing() {
  const url = getUrl()
    const { utm_source, utm_medium, utm_campaign } = getUtmValues(url)
    const data = {
      url,
      page_name: 'landing',
      utm_source,
      utm_medium,
      utm_campaign
    }
  page_viewed(data)

  return (
    <Box>
      <TopNavbar />
      <Header/>
      <LogoSection backgroundColor='var(--base-white, #FFF)'/>
      <Features />
      <Testimonials />
      <FrequentlyAskedQuestions/>
      <LatestCaseStudies/>
      <Potential/>
      <Footer />
    </Box>
  )
}
