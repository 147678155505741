import { Box } from '@mui/system'
import { useDispatch } from 'react-redux'
import Exit from '../../../../assets/exit.svg'
import { Typography } from '@mui/material'
import { logoutUser } from '../../../../store/actions/auth'

const MenuCardLogout = () => {
  const dispatch: any = useDispatch()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        // alignItems: 'flex-start',
        gap: '8px',
        marginTop: 'auto'
      }}
      onClick={() => {
        dispatch(logoutUser() as any)
      }}
    >
      <img src={Exit} alt="sideMenuLogoutIcon" />
      <Typography
        sx={{
          color: 'var(--text-icons-light-danger, #C8322B)',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '24px',
          letterSpacing: '-0.16px'
        }}
      >
        Log Out
      </Typography>
    </Box>
  )
}
export default MenuCardLogout
