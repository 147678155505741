import PropTypes from 'prop-types'
import { Box, List, Typography } from '@mui/material'
import NavItem from '../NavItem'

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }: any) => {
  // menu list collapse & items
  const items = item.children?.map((menu: any) => {
    switch (menu.type) {
      case 'item':
        return <NavItem key={menu.id} item={menu} level={1} />
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        )
    }
  })

  return (
    <Box>
      <List sx={{ display: 'flex', flexDirection: 'column', gap: '14px', padding: 0 }}>{items}</List>
    </Box>
  )
}

NavGroup.propTypes = {
  item: PropTypes.object
}

export default NavGroup
