import { identifyUser, logged_in, setUser, signup_completed } from 'services/mixpanel'
import {
  loginWithFirebase,
  loginWithFirebaseForOneTapLogin,
  logout,
  registerWithEmailFirebase,
  resetPassword,
  signUpWithFirebase
} from '../../services/auth'
import { LoginAuthProvider } from '../../types/auth'
import { NOTIFICATIONS } from '../../util/notification'
import {
  GET_AUTH_TOKEN_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  LOGOUT_USER_SUCCESS,
  RESET_PASSWORD_SENT_FAILURE,
  RESET_PASSWORD_SENT_SUCCESS,
  SETTING_USER_SUCCESS,
  SIGN_UP_USER_FAILURE,
  USER_ALREADY_LOGGED_IN_SUCCESS
} from '../actionTypes'
import { loadingStart, loadingStop, notificationFail, notificationSuccess } from './customization'
import { sendNewPublisherRegisteredEventToGTM } from 'services/googleTagManager'

export const login = (provider: LoginAuthProvider, req: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loadingStart())
    loginWithFirebase(provider, req).then(async ({ success, user, error }) => {
      if (success) {
        await dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: user?.login
        })
        dispatch({
          type: SETTING_USER_SUCCESS,
          data: user?.login
        })

        identifyUser(user?.email)
        setUser({ "$email": user?.email })
        logged_in({ "$email": user?.email, user_role: user?.user_type })

        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.loginSuccess))
        dispatch(loadingStop())
      } else {
        dispatch(loadingStop())
        dispatch(notificationFail(error, error?.message))
        dispatch({
          type: LOGIN_USER_FAILURE,
          payload: error
        })
      }
    })
  }
}

export const oneTaplogin = (req: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loadingStart())
    loginWithFirebaseForOneTapLogin(req).then(async ({ success, user, error }) => {
      if (success) {
        await dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: user?.login
        })
        dispatch({
          type: SETTING_USER_SUCCESS,
          data: user?.login
        })

        identifyUser(user?.email)
        setUser({ "$email": user?.email })
        logged_in({ "$email": user?.email, user_role: user?.user_type })

        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.loginSuccess))
        dispatch(loadingStop())
      } else {
        dispatch(loadingStop())
        dispatch(notificationFail(error, error?.message))
        dispatch({
          type: LOGIN_USER_FAILURE,
          payload: error
        })
      }
    })
  }
}
export const signup = (provider: LoginAuthProvider, req: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loadingStart())
    signUpWithFirebase(provider, req).then(async ({ success, user, error }) => {
      if (success) {
        await dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: user
        })
        dispatch({
          type: SETTING_USER_SUCCESS,
          data: user?.signup
        })
        identifyUser(user?.email)
        setUser({ "$email": user?.email })
        signup_completed({ "$email": user?.email, signup_mode: provider, user_role: user?.user_type })
        sendNewPublisherRegisteredEventToGTM({ userEmail: user?.email })

        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.signupSuccess))
        dispatch(loadingStop())
      } else {
        dispatch(loadingStop())
        dispatch(notificationFail(error, error?.message))
        dispatch({
          type: LOGIN_USER_FAILURE,
          payload: error
        })
      }
    })
  }
}

export const signUpWithEmail = (req: any, callBack: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loadingStart())
    registerWithEmailFirebase(req).then(async ({ success, error, user }) => {
      if (success) {
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.emailVerificationMailSent))
        dispatch(loadingStop())
        // to take back to user to login page
        callBack(1)
      } else {
        dispatch(loadingStop())
        dispatch(notificationFail(error, error?.message))
        dispatch({
          type: SIGN_UP_USER_FAILURE,
          payload: error
        })
      }
    })
  }
}

export const forgotPassword = (req: any, callBack: any) => {
  return (dispatch: any) => {
    resetPassword(req).then(({ success, response, error }) => {
      if (success) {
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.passwordSentSuccess))
        dispatch(loadingStop())
        callBack()
        dispatch({
          type: RESET_PASSWORD_SENT_SUCCESS,
          payload: response
        })
      } else {
        dispatch(notificationFail(NOTIFICATIONS.ERROR.passwordSentFail, error?.message))
        dispatch(loadingStop())
        dispatch({
          type: RESET_PASSWORD_SENT_FAILURE,
          payload: error
        })
        callBack()
      }
    })
  }
}

export const logoutUser = () => {
  return (dispatch: any) => {
    logout().then(({ success, error }) => {
      success
        ? dispatch({
          type: LOGOUT_USER_SUCCESS
        })
        : dispatch({
          type: LOGOUT_USER_FAILURE,
          payload: error
        })
    })
  }
}

export const getInitialState = (req: any) => {
  return (dispatch: any) => {
    dispatch({
      type: USER_ALREADY_LOGGED_IN_SUCCESS,
      payload: req
    })
  }
}

export const getAuthToken = () => {
  return (dispatch: any) => {
    getAuthToken()
    dispatch({
      type: GET_AUTH_TOKEN_SUCCESS
    })
  }
}

export const setAuthToken = () => {
  return (dispatch: any) => {
    setAuthToken()
    dispatch({
      type: GET_AUTH_TOKEN_SUCCESS
    })
  }
}
