import { Dialog, DialogContent } from '@mui/material'
import Login from '../auth/Login'
// import LoginWithEmail from '../auth/LoginWithEmail'
import Register from '../auth/Register'
import ForgotPasswordDialog from './ForgotPasswordDialog'
import { useState } from 'react'

export default function AuthModal(props: any) {
  const { open, handleClose, step, setStepValue, userEmail } = props
  
  const [openForgotPassword, setOpenForgotPassword] = useState<Boolean>(false)

  const handleCloseForgotPassword = () => {
    handleClose()
    setOpenForgotPassword(false)
  }

  const handleOpenForgotPassword = () => {
    setOpenForgotPassword(true)
  }

  const backToLogin = () => {
    setStepValue(1)
    setOpenForgotPassword(false)
  }

  const renderUI = () => {
    switch (step) {
      case 1:
        return (
          <Login
            step={step}
            userEmail={userEmail}
            handleClose={handleClose}
            setStepValue={setStepValue}
            handleOpenForgotPassword={handleOpenForgotPassword}
          />
        )

      case 2:
        return <Register step={step} handleClose={handleClose} setStepValue={setStepValue} />

      default:
        break
    }
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <DialogContent sx={{ padding: { xs: '32px 16px', md: '32px 48px' } }}>{renderUI()}</DialogContent>
      {openForgotPassword && (
        <ForgotPasswordDialog
          backToLogin={backToLogin}
          open={openForgotPassword}
          handleClose={handleCloseForgotPassword}
        />
      )}
    </Dialog>
  )
}
