import adminPages from './adminPages'
import pages from './pages'

// ==============================|| MENU ITEMS ||============================== //

export const menuItems = {
  items: [pages]
}

export const adminMenuItems = {
  items: [adminPages]
}
