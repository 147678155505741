import { Avatar } from '@mui/material'
import { stringAvatar } from '../../util/constants'

const UserAvatar = ({ profile_image, name, sx = undefined }: any) => {
  const userFirst = stringAvatar(name)?.toUpperCase()
  const sxApplied = sx || null
  return (
    <>
      {profile_image ? (
        <Avatar sx={sxApplied} alt={userFirst} src={profile_image} />
      ) : (
        <Avatar sx={sxApplied}>{userFirst}</Avatar>
      )}
    </>
  )
}
export default UserAvatar
