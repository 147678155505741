import Cookies from "js-cookie";
import { CONFIG } from "./config";
import { addDays } from "date-fns";

export const setEncryptedCookie = (cookieKey: string, cookieValue: string): void => {
  try {
    const { ENCRYPTION_KEY } = CONFIG
    const encryptedCookieValue = encryptString(cookieValue, ENCRYPTION_KEY)
    Cookies.set(cookieKey, encryptedCookieValue, {
      secure: false,
      expires: addDays(new Date(), 30)
    })
  } catch (error: any) {
  }

}

export const getEncryptedCookie = (cookieKey: string): string => {
  try {
    const { ENCRYPTION_KEY } = CONFIG
    const encryptedCookieValue = Cookies.get(cookieKey)
    if (encryptedCookieValue) {
      return decryptString(encryptedCookieValue, ENCRYPTION_KEY)
    }
    return 'customer'
  } catch (error: any) {
    return 'customer'
  }
}

function encryptString(inputString: string, secretKey: any) {
  let encryptedString = '';
  for (let i = 0; i < inputString.length; i++) {
    const charCode = inputString.charCodeAt(i) ^ secretKey.charCodeAt(i % secretKey.length);
    encryptedString += String.fromCharCode(charCode);
  }
  return encryptedString;
}

function decryptString(encryptedString: string, secretKey: any) {
  let decryptedString = '';
  for (let i = 0; i < encryptedString.length; i++) {
    const charCode = encryptedString.charCodeAt(i) ^ secretKey.charCodeAt(i % secretKey.length);
    decryptedString += String.fromCharCode(charCode);
  }
  return decryptedString;
}
