import React, { useState } from 'react'
import { Box, Typography, Button } from '@mui/material'
import LogoSection from './LogoSection'
import AuthModal from '../../../../views/modals/AuthDialog'

const Potential = () => {
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(2)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const setStepValue = (value: any) => {
    setStep(value)
  }

  return (
    <Box sx={Style.mainBox}>
      <Box sx={Style.innerMainBox}>
        <Box sx={Style.headerBox}>
          <Box sx={Style.headingBox}>
            <Typography sx={Style.startNow}>Start Now</Typography>
            <Typography sx={Style.heading}>2x Earning Potential in 30 Days</Typography>
          </Box>
          <Button
            onClick={(props) => {
              setStep(2)
              handleClickOpen()
            }}
            variant="contained"
            size="large"
            id='potential-signUpButton'
            sx={{ color: '#FFF', background: '#5828C4', fontSize: '17px', ':hover': {
              boxShadow: '3px 3px 10px 1px rgba(0, 13, 185, 0.6)'
            }}}
          >
            Sign Up (Free)
          </Button>
        </Box>
        <Box sx={{ width: { xs: '100%', md: '140%' } }}>
          <LogoSection backgroundColor="#F8F9FB" />
        </Box>
      </Box>
      {open && <AuthModal open={open} handleClose={handleClose} step={step} setStepValue={setStepValue} />}
    </Box>
  )
}
const Style = {
  mainBox: {
    display: 'flex',
    padding: { xs: '40px 16px', md: '64px 112px' },
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    background: 'var(--background-light-base-second, #F8F9FB)'
  },
  innerMainBox: {
    display: 'flex',
    padding: { xs: 0, md: '40px 112px' },
    flexDirection: 'column',
    alignItems: 'center',
    gap: { xs: 0, md: '48px' },
    alignSelf: 'stretch'
  },
  headerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: { xs: '16px', md: '32px' },
    alignSelf: 'stretch'
  },
  headingBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px'
  },
  startNow: {
    color: 'var(--text-icons-light-primary, #2D146F)',
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '-0.1px',
    alignSelf: 'stretch'
  },
  heading: {
    color: 'var(--text-icons-light-base-main, #323539)',
    textAlign: 'center',
    fontSize: { xs: '24px', md: '32px' },
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: { xs: '32px', md: '40px' },
    letterSpacing: '-0.48px',
    alignSelf: 'stretch'
  }
}
export default Potential
