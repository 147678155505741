import * as actionTypes from '../../actionTypes';
import { updateObject } from '../../shared/utility';
import { initialDate } from 'util/constants';

interface actionType {
  type: string
  payload: any
}

const initialState = {
  websiteList: [],
  websiteListAll: [],
  selectedWebsiteFilter: '',
  selectedSearchFilter: '',
  totalWebsiteCount: 0,
  websiteCount: {},
  revenueDetail : {},
  revenueWebsites :[],
  revenueCountries :[],
  earningBreakdownData : [],
  selectedTimeFilter:initialDate,
  selectedBreakDownType:'',
  pageNumber: 0,
  exportCSV: false
};
const userReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case actionTypes.WEBSITE_LIST:
      return updateObject(state, {
        loading: false,
        websiteList: action.payload
      })
    case actionTypes.EXPORT_WEBSITE_LIST:
      return updateObject(state, {
        loading: false,
        websiteListAll: action.payload
      })
    case actionTypes.SET_SELECTED_FILTER:
      return updateObject(state, {
        loading: false,
        selectedWebsiteFilter: action?.payload
      })
    case actionTypes.SET_SEARCH_FILTER:
      return updateObject(state, {
        loading: false,
        selectedSearchFilter: action?.payload
      })
    case actionTypes.SET_TOTAL_WEBSITE_COUNT:
      return updateObject(state, {
        loading: false,
        totalWebsiteCount: action?.payload
      })
    case actionTypes.SET_WEBSITE_COUNT:
      return updateObject(
        state, {
        loading: false,
        websiteCount: action.payload
      }
      )
    case actionTypes.SET_EXPORT_CSV:
      return updateObject(
        state, {
        loading: false,
        exportCSV: action.payload
      }
      )
    case actionTypes.SET_REVENUE_DETAILS:
      return updateObject(
        state, {
        loading: false,
        revenueDetail: action.payload
      }
      )
    case actionTypes.SET_REVENUE_WEBSITES:
      return updateObject(state, {
          loading: false,
          revenueWebsites: action?.payload
        })
    case actionTypes.SET_REVENUE_COUNTRIES:
      return updateObject(state, {
        loading: false,
        revenueCountries: action?.payload
      })
    case actionTypes.SET_REVENUE_TIME:
      return updateObject(state, {
        loading: false,
        selectedTimeFilter: action?.payload
      })
    case actionTypes.SET_EARNING_BREAKDOWN:
      return updateObject(state, {
        loading: false,
        earningBreakdownData: action?.payload
      })
    default:
      return state
  }
}

export default userReducer