import { gql } from '@apollo/client'

export const addWebsite = gql`
  mutation addWebsite($name: String!, $screen_shot_url: String!) {
    addWebsite(name: $name, screen_shot_url: $screen_shot_url) {
      _id
      name
      screen_shot_url
    }
  }
`;

export const getWebsiteList = gql`
  query viewWebsiteList(
    $skip: Float,
    $limit: Float,
    $user_id: String,
    $columnName: String,
    $order: String,
    $status: [String!],
    $search: String
  ) {
    viewWebsiteList(
      skip: $skip,
      limit: $limit,
      user_id: $user_id,
      columnName: $columnName,
      order: $order,
      status: $status,
      search: $search
    ) {
      websites {
        _id
        user_id
        name
        analytics
        screen_shot_url
        status
        commission
        created_at
        updated_at
      }
      page
      limit
      total_records
    }
  }
`;

export const getWebsiteCounts = gql`
  query getWebsiteCounts {
    getWebsiteCounts {
      activeCount
      approvedCount
      pendingCount
      rejectedCount
      disconnectedCount
    }
  }
`;

export const getRevenueDetails = gql`
  query RevenueDetail(
    $user_id: String,
    $website_list: [String!],
    $ad_unit_list: [String!],
    $countries: [String!],
    $fromDate: String,
    $toDate: String
  ) {
    revenueDetail(
      user_id: $user_id,
      website_list: $website_list,
      ad_unit_list: $ad_unit_list,
      countries: $countries,
      fromDate: $fromDate,
      toDate: $toDate
    ) {
      keyMetrics
      revenueByCountry
      revenueByDate
      revenueByWebsite
    }
  }
`;

export const getEarningsBreakdown = gql`
  query EarningsBreakdown(
    $user_id: String,
    $website_list: [String!],
    $ad_unit_list: [String!],
    $countries: [String!],
    $fromDate: String,
    $toDate: String
    $break_down_type: String,
  ) {
    earningsBreakdown(
      user_id: $user_id,
      website_list: $website_list,
      ad_unit_list: $ad_unit_list,
      countries: $countries,
      fromDate: $fromDate,
      toDate: $toDate,
      break_down_type: $break_down_type,
    ) {
      data
    }
  }
`