export type userData = {
    "$email": string
    user_type?: string
    first_name?: string
    last_name?: string
    company_name?: string
    phone?: number
}

export type pageView = {
    url: string
    page_name: string
    utm_source: string
    utm_medium: string
    utm_campaign: string
    platform?: string
}

export type signUpButtonClicked = {
    url: string
    page_name: string
    platform?: string
}

export type signUpInitiated = {
    signup_mode: string
    platform?: string
}

export type signUpCompleted = {
    "$email": string
    signup_mode: string
    user_role: string
    platform?: string
}

export type loggedIn = {
    "$email": string
    user_role: string
    platform?: string
}

export type webappButtonClicked = {
    button_name: string
    platform?: string
}

export type addNewWebsiteButtonClicked = {
    platform?: string
}

export type addNewWebsiteStepsCompleted = {
    platform?: string
}

export type platform = {
    platform?: string
}

export type onboardingImageSequenceCompleted = {
    platform?: string
}

export type homePageButtonClicked = {
    button_name: string
    platform?: string
}

export type feedbackSubmitted = {
    subject: string
    feedback: string
}

export type newWebsiteSubmitted = {
    domain_name: string
}

export enum Event {
    PageViewed = "page_viewed",
    SignupButtonClicked = "signup_button_clicked",
    SignupInitiated = "signup_initiated",
    SignupCompleted = "signup_completed",
    LoggedIn = "logged_in",
    NewCampaignButtonClicked = "new_campaign_button_clicked",
    CampaignCreationInitiated = "campaign_creation_initiated",
    TargetingDetailsAdded = "targeting_details_added",
    CampaignBudgetDetailsAdded = "campaign_budget_details_added",
    CampaignBudgetSubmitted = "campaign_budget_submitted",
    UploadBannerClicked = "upload_banner_clicked",
    BannerUploaded = "banner_uploaded",
    GetBannerClicked = "get_banner_clicked",
    CampaignPreviewed = "campaign_previewed",
    CampaignPublished = "campaign_published",
    WebappButtonClicked = "webapp_button_clicked",
    TopUpInitiated = "topup_initiated",
    TopUpCompleted = "topup_completed",
    HomepageButtonClicked = "homepage_button_clicked",
    FeedbackSubmitted = "feedback_submitted",
    OnboardingImageSequenceCompleted = "onboarding_image_sequence_completed",
    AddNewWebsiteButtonClicked = "add_new_website_button_clicked",
    AddNewWebsiteStep1Completed = "add_new_website_step1_completed",
    AddNewWebsiteStep2Completed = "add_new_website_step2_completed",
    AddNewWebsiteStep3Completed = "add_new_website_step3_completed",
    AddNewWebsiteStep4Completed = "add_new_website_step4_completed",
    AnalyticsScreenShotUploadInitiated = "analytics_screenshot_upload_initiated",
    AnalyticsScreenShotUploadCompleted = "analytics_screenshot_upload_completed",
    NewWebsiteSubmitted = "new_website_submitted",
    WebsiteApproved = "website_approved",
    WebsiteDeclined = "website_declined",
    WebsitePending = "website_pending",
    WebsiteActivated = "website_activated",
    WebsiteDisconnected = "website_disconnected"
}
