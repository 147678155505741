import { Box, IconButton } from '@mui/material'
import Logo from '../LogoSection/Logo'
import { NavSection } from './navSection'
import { Search } from './search'
import { UserInfo } from './userInfo'
import { Notifications } from './notifications'
import { HiMenuAlt3 } from 'react-icons/hi'

// import { Logout } from './logout'
interface AppHeaderProps {
  handleDrawerToggle: () => void;
}
export const AppHeader: React.FC<AppHeaderProps> = ({ handleDrawerToggle }) => {
  return (
    <Box sx={Style.mainBox}>
      <Box><Logo /></Box>
      <Box sx={Style.navSection}>
        <NavSection />
      </Box>
      <Box sx={Style.topLeftActionBox}>
        {/* <Search />
        <Notifications /> */}
        <UserInfo />
        {/* <Logout /> */}
      </Box>
      <IconButton color="inherit"
        id="open_drawer"
        aria-label="open drawer"
        edge="start"
        sx={Style.menuButton}
        onClick={handleDrawerToggle}
      >
        <HiMenuAlt3 />
      </IconButton>
    </Box>
  )
}

const Style = {
  mainBox: {
    display: 'flex',
    flex: 1,
    width: '100%',
    height: '70px',
    flexDirection: 'row',
    // justifyContent: 'center',
    alignItems: 'center',
    flexShrink: '0',
    padding: '0px 32px',
    borderBottom: '1px solid var(--stroke-light-base, #E5E5E7)',
    background: 'var(--background-light-base-main, #FFF)',
    '@media (max-width: 760px)': {
      display: "flex",
      justifyContent: "space-between",
      padding: "16px 16px"
    },
  },
  navSection: {
    '@media (max-width: 760px)': {
      display: 'none'
    },
  },
  topLeftActionBox: {
    display: "flex",
    '@media (max-width: 760px)': {
      display: 'none'
    },
    flexDirection: 'row',
    gap: '12px',
    ml: 'auto'
  },
  menuButton: {
    width: '45px',
    height: '45px',
    mr: 0,
    '@media (min-width: 760px)': {
      display: 'none'
    }
  }
}
