import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Typography } from '@mui/material'
import Box from '@mui/material/Box'
// import { useTheme } from '@mui/material/styles'
import { useDispatch } from 'react-redux'
// import { login } from '../../store/actions'
import { LoginAuthProvider } from '../../types/auth'
import FacebookSocialButton from './authbuttons/FacebookSocialButton'
import GoogleLoginButton from './authbuttons/GoogleLoginButton'
import LoginForm from './LoginForm'
import { login } from '../../store/actions/auth'
// import { Password } from '@mui/icons-material'

interface propsType {
  handleClose: any
  step: any
  setStepValue: any
  handleOpenForgotPassword: any
  userEmail?: string
}

export default function Login(props: propsType) {
  const { handleClose, step, setStepValue, handleOpenForgotPassword, userEmail } = props
  const dispatch = useDispatch()

  const handleLogin = async (provider: LoginAuthProvider, args?: { email: string; password: string }) => {
    dispatch(login(provider, args) as any)
  }

  // const theme = useTheme()

  return (
    <Box id="loginDialog">
      <Box
        className="modal-header"
        sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', padding: '20px' }}
      >
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Typography variant="h3" sx={{ fontSize: '36px' }}>
            Welcome Back!
          </Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {/* Login Email */}
      <Box sx={{ p: '20px' }}>
        <GoogleLoginButton handleLogin={handleLogin} />
        {/* <FacebookSocialButton handleLogin={handleLogin} /> */}
      </Box>
      <Box sx={Style.suggestionBox}>
        <Box sx={Style.line}></Box>
        <Box sx={Style.suggestion}>Or sign in with your email</Box>
        <Box sx={Style.line}></Box>
      </Box>
      <LoginForm
        handleOpenForgotPassword={handleOpenForgotPassword}
        step={step}
        handleLoginOrSignUp={handleLogin}
        setStepValue={setStepValue}
        userEmail={userEmail}
      />
    </Box>
  )
}
const Style = {
  suggestionBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    alignSelf: 'stretch',
    mb: 2
  },
  suggestion: {
    color: 'var(--gray-700, var(--gray-700, #323539))',

    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.16px'
  },
  line: {
    height: '1px',
    width: { xs: '13%', sm: '22%', md: '25%' },
    background: 'var(--neutral-600, #EAEBF0)'
  }
}
