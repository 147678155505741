export enum LoginAuthProvider {
  Facebook = 'facebook',
  Google = 'google',
  Email = 'email'
}

export type User = {
  _id: string
  refreshToken: string
}

export type LoginResponse = {
  success: boolean
  user?: User
  error?: Error
}

export type LogoutResponse = {
  success: boolean
  error?: Error
}
