import { useEffect, useState } from 'react'
import { Link } from 'react-scroll'


// Components
import Backdrop from '../Elements/Backdrop'
// Assets
import { Box, Button, IconButton, useTheme } from '@mui/material'
import { HiMenuAlt3 } from 'react-icons/hi'
import AuthModal from '../../../../views/modals/AuthDialog'
import Logo from '../../../MainLayout/LogoSection/Logo'
import SidebarHome from './SidebarHome'
import { BACKGROUND_LIGHT_BASE_MAIN } from '../../../../constants/colors'
import { BOOK_DEMO_POPUP, LIVE_CHAT } from '../../../../constants/redirectLinks'
import CalendlyHelmet from '../../../../util/calendlyHelmet';
import { signup_completed } from 'services/mixpanel'
import { sendNewPublisherRegisteredEventToGTM } from 'services/googleTagManager'
import { sendNewPublisherRegisteredEventToFbPixel } from 'services/facebookPixel'

declare const Calendly: {
  initPopupWidget(options: { url: string }): void;
};

export default function TopNavbar() {
  const theme: any = useTheme()

  const [y, setY] = useState(window.scrollY)
  const [sidebarOpen, toggleSidebar] = useState(false)
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(1)
  const [userEmail, setUserEmail] = useState<string>('')

  const urlParams = new URLSearchParams(window.location.search);
  const emailVerified = urlParams.get('emailVerified');

  const userEmailFromURL = urlParams.get('email') || ''; // Fallback to 'test' if no email param

  useEffect(() => {
    // auto email input code
    if (emailVerified === 'true') {
      setUserEmail(userEmailFromURL)
      signup_completed({ "$email": userEmailFromURL, signup_mode: 'email', user_role: 'customer' })
      sendNewPublisherRegisteredEventToGTM({ userEmail: userEmailFromURL })
      sendNewPublisherRegisteredEventToFbPixel({ userEmail: userEmailFromURL })
      setTimeout(() => {
        // Your operation goes here.
        setOpen(true)
      }, 500);

    }
  }, [])

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const setStepValue = (value: any) => {
    setStep(value)
  }

  useEffect(() => {
    window.addEventListener('scroll', () => setY(window.scrollY))
    return () => {
      window.removeEventListener('scroll', () => setY(window.scrollY))
    }
  }, [y])

  return (
    <Box>
      <CalendlyHelmet />
      <SidebarHome sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <nav
        className="flexCenter animate lightBg"
        id='topNavbar'
        style={{
          height: y > 100 ? '60px' : '80px',
          padding: '0px',
          width: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 999,
          background: BACKGROUND_LIGHT_BASE_MAIN,
          borderBottom: '1px solid #E5E5E7'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            position: 'relative',
            width: '100%',
            height: '100%',
            padding: {
              xs: '0px 15px',
              md: '0px 112px'
            }
          }}
        // className="container-modified flexSpaceCenter"
        >
          <Box sx={{ display: 'flex', flexWrap: "nowrap", flexDirection: 'row', gap: { xs: 0, md: "70px", lg: '120px' }, maxHeight: '75px', alignItems: 'center', width: { xs: "100%", md: "70%" } }}>
            <Link
              style={{ padding: '5px 14px' }}
              offset={-60}
              className="pointer flexNullCenter"
              to="home"
              smooth={true}
              id='logoLink'
            >
              <Logo />
            </Link>
            <IconButton
              color="inherit"
              id="menuIconButton"
              aria-label="open drawer"
              edge="start"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                borderRadius: '0px',
                color: theme.palette.secondary.dark,
                width: '55px',
                height: '55px',
                padding: 0,
                mr: 0,
                ml: 'auto',
                '@media (min-width: 760px)': {
                  display: 'none'
                }
              }}
              onClick={() => toggleSidebar(!sidebarOpen)}
            >
              <HiMenuAlt3 />
            </IconButton>

            <Box
              component={'ul'}
              className="flexNullCenter"
              id='navbarMainLinks'
              sx={{
                display: 'flex',
                '@media (max-width: 760px)': {
                  display: 'none'
                },
                gap: '32px'
              }}
            >
              <li className="semiBold font15 pointer">
                <Link activeClass="active" to="home" id="homeLink" spy={true} smooth={true}>
                  Home
                </Link>
              </li>
              <li className="semiBold font15 pointer ">
                <Link activeClass="active" to="Features" id="featuresLink" spy={true} smooth={true}>
                  Features
                </Link>
              </li>
              <li className="semiBold font15 pointer ">
                <div id="bookDemoLink"
                  onClick={(e) => {
                    e.preventDefault();
                    Calendly.initPopupWidget({
                      url:
                        `${BOOK_DEMO_POPUP}`
                    });
                    return false;
                  }}>
                  Book Demo
                </div>
              </li>
              <li className="semiBold font15 pointer">
                <a id="liveChatLink" href={LIVE_CHAT} target="_blank" rel="noreferrer">
                  Live Chat
                </a>
              </li>
            </Box>
          </Box>
          <Box
            component={'ul'}
            sx={{
              '@media (max-width: 760px)': {
                display: 'none'
              }
            }}
            className="flexNullCenter"
          >
            <li>
              <Button
                id="loginButton"
                color="primary"
                sx={{ mr: 1 }}
                onClick={() => {
                  handleClickOpen()
                  setStep(1)
                }}
              >
                Log in
              </Button>
              <Button
                id="signUpButton"
                onClick={() => {
                  handleClickOpen()
                  setStep(2)
                }}
                variant="contained"
              >
                Sign Up
              </Button>
            </li>
          </Box>

        </Box>
      </nav>
      {open && <AuthModal open={open} userEmail={userEmail} handleClose={handleClose} step={step} setStepValue={setStepValue} />}
    </Box>
  )
}
