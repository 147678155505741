import { Close } from '@mui/icons-material'
// import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import { Box, Button } from '@mui/material'
// import { useTheme } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { Link } from 'react-scroll'
import AuthModal from '../../../../views/modals/AuthDialog'
import LogoSection from '../../../MainLayout/LogoSection'
import { BOOK_DEMO_POPUP } from '../../../../constants/redirectLinks'
import CalendlyHelmet from '../../../../util/calendlyHelmet'
import telegramIcon from '../../../../assets/telegramIcon.webp'
import linkedinIcon from '../../../../assets/linkedinIcon.webp'
import twitterIcon from '../../../../assets/twitterIcon.webp'
import { redirectToNewPage } from '../../../../util/redirect'
import { SOCIALS } from '../../../../constants/redirectLinks'
declare const Calendly: {
  initPopupWidget(options: { url: string }): void
}

export default function SidebarHome({ sidebarOpen, toggleSidebar }: any) {
  // const theme: any = useTheme()

  const [y, setY] = useState(window.scrollY)
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(1)

  const handleClickOpen = (value: any) => {
    setStep(value)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const setStepValue = (value: any) => {
    setStep(value)
  }

  useEffect(() => {
    window.addEventListener('scroll', () => setY(window.scrollY))
    return () => {
      window.removeEventListener('scroll', () => setY(window.scrollY))
    }
  }, [y])

  const custom = {
    padding: '10px 15px',
    width: '100%',
    display: 'inline-block',
    margin: '0 0 10px 0',
    fontSize: '20px',
    fontWeight: '500'
  }

  return (
    <Box>
      <CalendlyHelmet />
      <nav
        className="animate"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100vh',
          background: 'white',
          position: 'fixed',
          top: '0',
          right: sidebarOpen ? '0px' : '-400px',
          zIndex: 9999,
          minWidth: '250px',
          width: '250px',
          maxWidth: '250px',
        }}
        id='sidebarBox'
      >
        <Box>
          <Box
            sx={{
              padding: '20px 0px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <div className="flexNullCenter">
              <LogoSection />
            </div>
            <Button id="sidebar-btnclose" onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
              <Close />
            </Button>
          </Box>
          <ul
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <li className="semiBold font15 pointer" style={{ width: '100%' }}>
              <Link
                onClick={() => toggleSidebar(!sidebarOpen)}
                activeClass="active"
                style={custom}
                id='sidebar-featuresLink'
                to="Features"
                spy={true}
                smooth={true}
                offset={-60}
              >
                Features
              </Link>
            </li>
            <li className="semiBold font15 pointer" style={{ width: '100%' }}>
              <Link
                onClick={() => toggleSidebar(!sidebarOpen)}
                activeClass="active"
                style={custom}
                id='sidebar-testimonialsLink'
                to="Testimonials"
                spy={true}
                smooth={true}
                offset={-60}
              >
                Testimonials
              </Link>
            </li>
            <li className="semiBold font15 pointer" style={{ width: '100%' }}>
              <Box style={custom} id="btnDemo" sx={{ marginLeft: '0.3rem', fontSize: '1.2rem' }}>
                <div
                  onClick={(e) => {
                    e.preventDefault()
                    Calendly.initPopupWidget({
                      url: `${BOOK_DEMO_POPUP}`
                    })
                    return false
                  }}
                  style={{ color: 'black' }}
                >
                  Book Demo
                </div>
              </Box>
            </li>
            <li className="semiBold font15 pointer" style={{ width: '100%' }}>
              <Link
                onClick={() => toggleSidebar(!sidebarOpen)}
                activeClass="active"
                style={custom}
                id='sidebar-FAQsLink'
                to="Faq"
                spy={true}
                smooth={true}
                offset={-60}
              >
                FAQs
              </Link>
            </li>
            <li className="semiBold  pointer " style={{ width: '100%', paddingLeft: '5px' }}>
              <Button
                style={custom}
                id="sidebar-btnSignUp"
                color="primary"
                sx={{ fontSize: '20px !important' }}
                onClick={() => {
                  handleClickOpen(2)
                  toggleSidebar(!sidebarOpen)
                }}
              >
                Sign Up
              </Button>
            </li>
            <li className="semiBold  pointer " style={{ width: '100%', paddingLeft: '5px' }}>
              <Button
                style={custom}
                id="sidebar-btnLogin"
                color="primary"
                sx={{ fontSize: '20px !important' }}
                onClick={() => {
                  handleClickOpen(1)
                  toggleSidebar(!sidebarOpen)
                }}
              >
                Log in
              </Button>
            </li>
            {/* <li className="semiBold  pointer flexCenter">
            <Button
              id="btnStartedd"
              title="Get Started"
              className="animate greenButton"
              variant="contained"
              onClick={() => {
                handleClickOpen(2)
                toggleSidebar(!sidebarOpen)
              }}
              sx={{
                padding: '10px 24px',
                fontSize: '18px',
                background: theme.palette.secondary.green,
                '&:hover': { background: '#00e39cc2' }
              }}
            >
              Get Started
              <ArrowCircleRightIcon sx={{ ml: '10px' }} />
            </Button>
          </li> */}
          </ul>
        </Box>
        <Box sx={Style.iconContainer}>
          <Box sx={Style.icon} id='sidebar-linkedin' onClick={() => redirectToNewPage(SOCIALS.LINKEDIN)}>
            <img src={linkedinIcon} alt="linkedinIcon" />
          </Box>
          <Box sx={Style.icon} id='sidebar-telegram' onClick={() => redirectToNewPage(SOCIALS.TELEGRAM)}>
            <img src={telegramIcon} alt="telegramIcon" />
          </Box>
          <Box sx={Style.icon} id='sidebar-twitter' onClick={() => redirectToNewPage(SOCIALS.TWITTER)}>
            <img src={twitterIcon} alt="twitterIcon" />
          </Box>
        </Box>
      </nav>
      {open && <AuthModal open={open} handleClose={handleClose} step={step} setStepValue={setStepValue} />}
    </Box>
  )
}
const Style = {
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
    padding: '25px 15px'
  },
  icon: {
    cursor: 'pointer'
  }
}
