import {
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
  Checkbox
} from '@mui/material'
import Box from '@mui/material/Box'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { LoginAuthProvider } from '../../types/auth'
import { useSelector } from 'react-redux'

interface propsType {
  step: any
  handleLoginOrSignUp: (provider: LoginAuthProvider, args?: { email: string; password: string }) => void
  setStepValue: any
  handleOpenForgotPassword: any
  userEmail?: string
}

const LoginForm = (prop: propsType) => {
  const { step, handleLoginOrSignUp, setStepValue, handleOpenForgotPassword, userEmail } = prop
  const [checked, setChecked] = useState(false)

  const onClickRegister = () => {
    setStepValue(2)
  }

  const { isLoading } = useSelector((state: any) => state.customization)

  const SignInSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email Required').trim(' '),
    password: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Password Required')
  })
  const [isPassword, setIsPassword] = React.useState(true)
  const handleClickOpen = () => {
    handleOpenForgotPassword()
    setStepValue(3)
  }

  return (
    <Box>
      <Formik
        initialValues={{
          email: userEmail || '',
          password: ''
        }}
        validationSchema={SignInSchema}
        onSubmit={(values: { email: any; password: any }) => {
          values = { ...values, email: values.email.trim() }
          handleLoginOrSignUp(LoginAuthProvider.Email, values)
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm }: any) => (
          <Form style={{ width: '100%', padding: '0px 20px', boxSizing: 'border-box' }}>
            <Box sx={Style.inputBox}>
              <Typography sx={Style.textStyle}>Email Address</Typography>
              <TextField
                fullWidth
                id={'email'}
                placeholder="Insert your email"
                type={'text'}
                name={'email'}
                value={values?.email}
                onChange={handleChange}
                error={touched?.email && Boolean(errors?.email)}
                helperText={touched?.email && errors.email}
              />

              {errors?.email && (
                <FormHelperText error id="emailError">
                  {errors?.email}
                </FormHelperText>
              )}
            </Box>

            <Box sx={{ ...Style.inputBox, position: 'relative', marginTop: 2 }}>
              <Typography sx={Style.textStyle}>Password</Typography>
              <OutlinedInput
                fullWidth
                id={'password'}
                placeholder="Insert your password"
                type={isPassword ? 'password' : 'text'}
                name={'password'}
                value={values?.password}
                onChange={handleChange}
                error={touched?.password && Boolean(errors?.password)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      id="togglePasswordVisibilityIcon"
                      onClick={() => setIsPassword(!isPassword)}
                      aria-label="toggle password visibility"
                      onMouseDown={() => setIsPassword(!isPassword)}
                      edge="end"
                    >
                      {isPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors?.password && (
                <FormHelperText error id="passwordError">
                  {errors?.password}
                </FormHelperText>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'space-between',
                alignItems: { xs: 'flex-start', md: 'center' },
                alignSelf: 'stretch',
                mt: 2,
                gap: { xs: '10px', md: 0 }
              }}
            >
              <Box sx={Style.checkBox}>
                <Checkbox
                  id="keepMeLoggedInCheckbox"
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                  color="primary"
                  sx={{ width: '20px' }}
                />
                <Typography
                  sx={{
                    color: 'var(--gray-700, var(--gray-700, #323539))',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '-0.16px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  Keep me logged in
                </Typography>
              </Box>
              <Typography
                id='forgotPasswordButton'
                onClick={() => handleClickOpen()}
                sx={{
                  color: 'var(--background-light-primary-main, #5828C4)',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '22px',
                  cursor: 'pointer'
                }}
              >
                Forgot Password?
              </Typography>
            </Box>
            <Button
              id="continueButton"
              type="submit"
              disabled={isLoading}
              variant="contained"
              sx={{
                mt: 2,
                mb: 2,
                p: 0,
                width: '100% !important',
                height: '40px',
                color: '#fff !important',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {isLoading && (
                <CircularProgress
                  size={20}
                  sx={{
                    color: 'white',
                    marginRight: '20px'
                  }}
                />
              )}
              {step === 1 ? 'Continue' : 'SignUp'}
            </Button>
          </Form>
        )}
      </Formik>
      <Box sx={Style.suggestionBox}>
        <Typography sx={Style.suggestion}> Don't have an account?</Typography>
        <Typography onClick={onClickRegister} sx={Style.suggestionName}>
          Register
        </Typography>
      </Box>
      {/* {open && <ForgotPasswordDialog open={open} handleClose={handleCloseModal} />} */}
    </Box>
  )
}
const Style = {
  inputBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch'
  },
  textStyle: {
    color: 'var(--text-icons-light-base-main, #323539)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.1px'
  },
  checkBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    padding: 0
  },
  suggestionBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch'
  },
  suggestion: {
    color: 'var(--gray-50, var(--gray-50, #858C95))',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.16px'
  },
  suggestionName: {
    color: 'var(--background-light-primary-main, #5828C4)',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    cursor: 'pointer'
  }
}
export default LoginForm
