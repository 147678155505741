import moment from "moment";
export enum AuthActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  REGISTER = 'REGISTER',
  UPDATE = 'UPDATE'
}

export enum NAVIGATION {
  HOME = '/',
  LOGIN = '/login',
  DETAIL_CAMPAIGN = '/detail-campaign',
  ACCOUNTS = '/accounts',
  USER_ACCOUNT = '/user_account',
  REQUESTS = '/requests',
  TRANSACTIONS = '/transactions'
}

export const DOT_TUTORIAL_VIDEO_URL = 'https://youtu.be/p0oSkICEeOQ'



export enum UserType {
  ADMIN = 'admin',
  CUSTOMER = 'customer'
}

export const getBucketName = (userId: string, type: string) => {
  return `/${userId}/${type}/`
}
export enum BucketType {
  WEBSITE = 'website',
  PROFILE = 'profile'
}

export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}


export enum FireBaseError {
  EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
  USER_NOT_FOUND = 'auth/user-not-found',
  INVALID_LOGIN_CREDENTIALS = 'auth/invalid-login-credentials',
  TOO_MANY_ATTEMPT = 'auth/too-many-requests',
  INVALID_PASSWORD = 'auth/wrong-password',
  EMAIL_EXISTS = 'This Email is already in use, try a different Email',
  EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
  OPERATION_NOT_ALLOWED = 'OPERATION_NOT_ALLOWED',
  NO_USER_FOUND = 'This Email is not registered',
  WRONG_PASSWORD = 'Entered Password is wrong, Please try again',
  PROBLEM_WITH_LOGIN_PLEASE_TRY_AGAIN = 'There was a problem with your login. Please try again',
  PROBLEM_WITH_SIGNUP_PLEASE_TRY_AGAIN = 'There was a problem with your signup. Please try again',
  FIREBASE_TOKEN_EXPIRED = 'Firebase token expired/invalid...!!',
  EMAIL_NOT_VERIFIED = 'Email is not verified, Please verify your email',
  PROBLEM_WITH_SENDING_EMAIL_VERIFICATION = 'There was a problem with sending email verification. Please try again'
}



export const IMAGE_MAX_SIZE = 150000

export const DEFAULT_CURRENCY = 'USD'
export const DEFAULT_CURRENCY_SYMBOL = '$'
export const DEFAULT_CURRENCY_FORMAT = 'en-US'
export const DEFAULT_CURRENCY_DECIMAL = 2
export const DEFAULT_CURRENCY_THOUSANDS = ','
export const DEFAULT_CURRENCY_DECIMALS = '.'
export const DEFAULT_CURRENCY_SYMBOL_AFTER = false
export const DEFAULT_CURRENCY_SYMBOL_SPACE = false

export const DEFAULT_CURRENCY_FORMAT_CONFIG = {
  symbol: DEFAULT_CURRENCY_SYMBOL,
  format: DEFAULT_CURRENCY_FORMAT,
  decimal: DEFAULT_CURRENCY_DECIMAL,
  thousand: DEFAULT_CURRENCY_THOUSANDS,
  precision: DEFAULT_CURRENCY_DECIMALS,
  formatWithSymbol: DEFAULT_CURRENCY_SYMBOL_AFTER,
  spaceBetweenAmountAndSymbol: DEFAULT_CURRENCY_SYMBOL_SPACE
}

export const DEFAULT_CAMPAIGN_LIST_LIMIT = 5

export default function CurrencyFormat(amount: any) {
  let values = '0'
  try {
    let isInteger = Number.isInteger(amount)
    if (isInteger) {
      values = amount
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else {
      values = Number.parseFloat(amount.toString())
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
  } catch (error) {}
  return '$' + values
}
export const REG_TRANSFER_AMOUNT = /^\d{1,}(\.\d{0,2})?$/



export const stringAvatar = (name: string) => {
  return name?.substring(0, 1)
}
export const checkURL = (url: any) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(url)
}


export const initialDate = {
  startDate: moment().subtract(6, 'days').endOf('day').toDate(),
  endDate: moment().endOf('day').toDate(),
  key: 'selection'
}

export const filterButtonsInWebsite = ["All", "Active", "Pending","Approved","Rejected","In Progress","Disconnected"];











