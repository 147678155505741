import { Box,IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../../../store/actions/auth'
import downArrow from '../../../assets/down.svg'
// import avatar from '../../../assets/Avatar3.png'
import UserAvatar from '../../ui-components/UserAvatar'
import { LuSettings } from 'react-icons/lu'
import { GrCircleInformation } from 'react-icons/gr'
import { BsArrowLeftShort } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

export const UserInfo = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const open = Boolean(anchorEl)
  const userDetails = useSelector((state: any) => state.settingReducer.userDetails)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box sx={Style.mainBox}>
      <Box sx={Style.userAvatar}>
        <UserAvatar
          name={userDetails?.full_name ? userDetails?.full_name : userDetails?.email}
          profile_image={userDetails?.profile_image}
        />
      </Box>
      <Box sx={Style.userName}>{userDetails?.full_name || 'Dot User'}</Box>
      <Box id="userOptions" onClick={(e) => handleClick(e)} sx={Style.menuOptions}>
        <img src={downArrow} alt="logout" style={Style.menuOptions} />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem id="helpMenu" onClick={() => navigate('/support')}>
          <IconButton color="primary">
            <GrCircleInformation />
          </IconButton>
          <Typography sx={Style.menuOptionText}>Help & Support</Typography>
        </MenuItem>
        {/* <Divider /> */}
        <MenuItem id="settingsMenu" onClick={() => navigate('/settings')}>
          <IconButton>
            <LuSettings color="black" />
          </IconButton>
          <Typography sx={Style.menuOptionText}>Settings</Typography>
        </MenuItem>
        {/* <Divider /> */}
        <MenuItem id="settingsMenu" onClick={() => dispatch(logoutUser() as any)}>
          <IconButton>
            <BsArrowLeftShort color="#e43a30" />
          </IconButton>
          <Typography sx={Style.menuOptionText} color="#e43a30">
            Log Out
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}

const Style = {
  mainBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 'var(--main-system-10-px, 10px)',
    ml: '10px'
  },
  userAvatar: {
    display: 'flex',
    width: '40px',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'var(--roundness-full-inside, 128px)',
    background: 'var(--avatar-male, url(<path-to-image>), lightgray 50% / cover no-repeat)'
  },
  userName: {
    color: 'var(--text-icons-light-base-main, #323539)',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px',
    maxWidth: '80px'
  },
  menuOptions: {
    cursor: 'pointer',
    '&:hover': {
      background: 'var(--background-light-base-hover, #F5F5F5)'
    },
    '&:active': {
      background: 'var(--background-light-base-active, #E5E5E5)'
    }
  },
  menuOptionText: {
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '22px',
    ml: '5px'
  }
}
