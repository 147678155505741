import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Logo from './Logo'

const FooterLogo = () => {
  const navigate = useNavigate()
  return (
    <Box onClick={() => navigate('/')} sx={{ maxHeight: '44px', paddingLeft: '1rem' }}>
      <Logo />
    </Box>
  )
}

export default FooterLogo
