import React, { useState } from 'react'
import { Box, Typography, Button, Grid, useMediaQuery } from '@mui/material'
import arrow from '../../../../../assets/arrowIcon.webp'
import menuIcon from '../../../../../assets/menuIcon.webp'
import chartImage from '../../../../../assets/chartImage.webp'
import janBar from '../../../../../assets/janBar.webp'
import febBar from '../../../../../assets/febBar.webp'
import marchBar from '../../../../../assets/marchBar.webp'
import aprilBar from '../../../../../assets/aprilBar.webp'
import mayBar from '../../../../../assets/mayBar.webp'
import juneBar from '../../../../../assets/juneBar.webp'
import julyBar from '../../../../../assets/julyBar.webp'
import augBar from '../../../../../assets/augBar.webp'
import AuthModal from '../../../../../views/modals/AuthDialog'

const FusionAI = () => {
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(2)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const setStepValue = (value: any) => {
    setStep(value)
  }

  const Screen = useMediaQuery('(max-width:900px)')
  return (
    <Box sx={Style.mainBox}>
      <Grid sx={Style.innerMainBox} container>
        <Grid sx={Style.contentBox} item xs={12} md={5}>
          <Box sx={Style.innerContentBox}>
            <Typography sx={Style.contentHeading}>Intelligence built-in</Typography>
            <Box sx={Style.descriptionAndParaBox}>
              <Typography sx={Style.description}>Maximize Revenue with DOT Fusion AI</Typography>
              <Typography sx={Style.para}>
                Dive into a unified solution that intelligently combines direct deals, programmatic demand, and
                sponsored content. DOT Fusion AI offers the fastest possible route to higher revenue.
              </Typography>
            </Box>
          </Box>
          {Screen ? null : (
            <Button
              sx={Style.buttonStyle}
              variant="contained"
              className="fusionAI-signUpButton"
              size="large"
              color="primary"
              endIcon={<img src={arrow} alt="externalLinkIcon" />}
              onClick={(props) => {
                setStep(2)
                handleClickOpen()
              }}
            >
              Sign Up
            </Button>
          )}
        </Grid>
        <Grid sx={Style.mainCardBox} item xs={12} md={7}>
          <Box sx={Style.innerCardBox}>
            <Box sx={Style.wrapperBox}>
              <Box sx={Style.firstCard}>
                <Box sx={Style.CardHeader} style={{ padding: '16px 20px' }}>
                  <Typography sx={Style.firstCardHeading}>Monthly earnings</Typography>
                  <Box>
                    <img src={menuIcon} alt="menuIcon" />
                  </Box>
                </Box>
                <Box sx={Style.chartContainer}>
                  <Box sx={Style.earningBox}>
                    <Typography sx={Style.earning}>$390</Typography>
                    <Typography sx={Style.earningGrowth}>+2% monthly growth</Typography>
                  </Box>
                  <Box sx={Style.chartBox}>
                    <img src={chartImage} alt="chartImage" />
                  </Box>
                </Box>
              </Box>
              <Box sx={Style.secondCard}>
                <Box sx={Style.CardHeader}>
                  <Typography sx={Style.secondCardHeading}>Analytics</Typography>
                  <Box>
                    <img src={menuIcon} alt="menuIcon" />
                  </Box>
                </Box>
                <Box sx={Style.barContainer}>
                  <Box>
                    <img src={janBar} alt="barIcon" />
                  </Box>
                  <Box>
                    <img src={febBar} alt="barIcon" />
                  </Box>
                  <Box>
                    <img src={marchBar} alt="barIcon" />
                  </Box>
                  <Box>
                    <img src={aprilBar} alt="barIcon" />
                  </Box>
                  <Box>
                    <img src={mayBar} alt="barIcon" />
                  </Box>
                  <Box>
                    <img src={juneBar} alt="barIcon" />
                  </Box>
                  <Box>
                    <img src={julyBar} alt="barIcon" />
                  </Box>
                  <Box>
                    <img src={augBar} alt="barIcon" />
                  </Box>
                </Box>
                <Box sx={Style.monthContainer}>
                  <Box sx={Style.monthName}>Jan</Box>
                  <Box sx={Style.monthName}>Feb</Box>
                  <Box sx={Style.monthName}>Mar</Box>
                  <Box sx={Style.monthName}>Apr</Box>
                  <Box sx={Style.monthName}>May</Box>
                  <Box sx={Style.monthName}>Jun</Box>
                  <Box sx={Style.monthName}>Jul</Box>
                  <Box sx={Style.monthName}>Aug</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {Screen ? (
        <Button
          sx={Style.buttonStyle}
          variant="contained"
          className="fusionAI-signUpButton"
          size="large"
          color="primary"
          endIcon={<img src={arrow} alt="externalLinkIcon" />}
          onClick={(props) => {
            setStep(2)
            handleClickOpen()
          }}
        >
          Sign Up
        </Button>
      ) : null}
      {open && <AuthModal open={open} handleClose={handleClose} step={step} setStepValue={setStepValue} />}
    </Box>
  )
}
const Style = {
  mainBox: {
    display: 'flex',
    // width: "var(--device-desktop, 1440px)",
    // height: "530px",
    padding: { xs: '60px 16px 40px 16px', md: '64px 112px' },
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--main-system-64-px, 64px)',
    background: 'var(--base-white, #FFF)'
  },
  innerMainBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: { xs: '60px', md: '0px' },
    alignSelf: 'stretch'
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '40px'
  },
  contentHeading: {
    color: 'var(--text-icons-light-primary, #2D146F)',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.14px',
    textTransform: 'uppercase'
  },
  innerContentBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: { xs: 'center', md: 'flex-start' },
    gap: '8px',
    alignSelf: 'stretch'
  },
  descriptionAndParaBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: { xs: 'center', md: 'flex-start' },
    gap: '20px',
    alignSelf: 'stretch'
  },
  description: {
    textAlign: { xs: 'center', md: 'left' },
    color: 'var(--text-icons-light-base-main, #323539)',
    paddingRight: '12px',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: '-0.48px',
    alignSelf: 'stretch'
  },
  para: {
    textAlign: { xs: 'center', md: 'left' },
    color: 'var(--text-icons-light-base-second, #858C95)',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.16px',
    alignSelf: 'stretch'
  },
  buttonStyle: {
    width: '236px',
    height: '54px',
    fontSize: '17px',
    ':hover': {
      boxShadow: '3px 3px 10px 1px rgba(0, 13, 185, 0.6)'
    }
  },
  mainCardBox: {
    display: 'flex',
    justifyContent: { xs: 'center' },
    alignItems: { xs: 'center' },
    flex: '1 0 0'
  },
  innerCardBox: {
    display: 'flex',
    width: { xs: '100%', sm: '486px' },
    padding: { xs: 0, md: '32px' },
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '28.8px',
    borderRadius: '18px',
    boxShadow: '0px 0.9px 1.8px 0px rgba(16, 24, 40, 0.04)'
  },
  wrapperBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '14.4px',
    alignSelf: 'stretch'
  },
  firstCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: 'var(--roundness-round-outside, 12px)',
    border: '1px solid var(--stroke-light-base, #E5E5E7)',
    background: 'var(--background-light-base-main, #FFF)',
    boxShadow: '0px 4px 8px 0px rgba(113, 128, 150, 0.08), 0px 0px 1px 0px rgba(113, 128, 150, 0.04)'
  },
  CardHeader: {
    display: 'flex',
    gap: '16px',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  firstCardHeading: {
    color: 'var(--text-icons-light-base-main, #323539)',

    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.16px',
    alignSelf: 'stretch'
  },
  menuIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '8px',
    position: 'absolute',
    right: '20px',
    top: '16px'
  },
  chartContainer: {
    display: 'flex',
    padding: '0px 20px 16px 20px',
    alignItems: 'flex-start',
    gap: '20px',
    alignSelf: 'stretch'
  },
  earningBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px'
  },
  earning: {
    color: 'var(--text-icons-light-base-main, #323539)',

    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '38px',
    letterSpacing: '-0.42px',
    alignSelf: 'stretch'
  },
  earningGrowth: {
    color: 'var(--text-icons-light-primary, #2D146F)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.1px'
  },
  chartBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '8px',
    flex: '1 0 0',
    alignSelf: 'stretch'
  },
  secondCard: {
    display: 'flex',
    padding: '14.4px 18px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '14.4px',
    flex: '1 0 0',
    alignSelf: 'stretch',
    borderRadius: '9px',
    border: '0.9px solid var(--neutral-600, #EAEBF0)',
    background: 'var(--base-white, #FFF)',
    boxShadow: '0px 0.9px 1.8px 0px rgba(16, 24, 40, 0.04)'
  },
  secondCardHeading: {
    color: 'var(--gray-700, #272D37)',
    fontSize: '13.5px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19.8px'
  },
  barContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: '1 0 0',
    alignSelf: 'stretch'
  },
  monthContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    flex: '1 0 0'
  },
  monthName: {
    color: 'var(--gray-25, var(--gray-25, #979AA0))',
    fontSize: '12.6px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '14.4px'
  }
}
export default FusionAI
