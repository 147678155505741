import React from 'react'
import { Typography, Box } from '@mui/material'
import Marquee from 'react-fast-marquee'
import forexLiveLogo from '../../../../assets/forexLiveLogo.webp'
import benzingaLogo from '../../../../assets/benzingaLogo.webp'
import bscNewLogo from '../../../../assets/bscNewLogo.webp'
import cryptoGlobeLogo from '../../../../assets/cryptoGlobeLogo.webp'
import cryptoPolitenLogo from '../../../../assets/cryptoPolitenLogo.webp'
import nairaMetricsLogo from '../../../../assets/nairaMetricsLogo.webp'
const Logos = [
    { id: '1', logo: cryptoGlobeLogo },
    { id: '2', logo: nairaMetricsLogo },
    { id: '3', logo: cryptoPolitenLogo },
    { id: '4', logo: forexLiveLogo },
    { id: '5', logo: benzingaLogo },
    { id: '6', logo: bscNewLogo }
]
interface LogoSectionProps {
    backgroundColor: string
}
const LogoSection: React.FC<LogoSectionProps> = ({ backgroundColor }) => {
    return (
        <Box sx={{ ...Style.mainBox, background: backgroundColor }}>
            <Box sx={Style.headingBox}>
                <Typography sx={Style.heading}>Join Hundreds of Premium Publishers</Typography>
            </Box>
            <Box className="home-slider-box container">
                <Marquee gradient={false}>
                    {Logos.map((logo: any, i: any) => (
                        <Box key={i} sx={Style.itemBox}>
                            <img src={logo.logo} alt="logo" />
                        </Box>
                    ))}
                </Marquee>
            </Box>
        </Box>
    )
}
const Style = {
    mainBox: {
        display: 'flex',
        // width: "var(--device-desktop, 1440px)",
        padding: { xs: '24px 12px', md: '50px 112px' },
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '32px'
    },
    headingBox: {
        width: '100%',
        display: 'flex',
        maxWidth: '1280px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
        // gap: "12px",
        // alignSelf: "stretch"
    },
    heading: {
        display: 'flex',
        alignItems: 'center',
        color: '#1C1C1C',
        textAlign: 'center',
        fontFeatureSettings: "'clig' off, 'liga' off",
        
        fontSize: { xs: '16px', md: '24px' },
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: { xs: '24px', md: '30px' }
    },
    itemBox: {
        display: 'flex',
        height: '80px',
        minWidth: '200px',
        maxWidth: '200px',
        padding: '24px 32px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px',
        borderRadius: '16px',
        border: '1px solid #EEEBEB',
        background: '#FFF',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
        margin: '5px'
    }
}
export default LogoSection
