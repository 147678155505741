import { Box, Typography, useMediaQuery } from '@mui/material'
import onboardingImageFive from '../../../constants/onboardingImages/onboardingImageFive.webp'
import onboardingImageFour from '../../../constants/onboardingImages/onboardingImageFour.webp'
import onboardingImageOne from '../../../constants/onboardingImages/onboardingImageOne.webp'
import onboardingImageTwo from '../../../constants/onboardingImages/onboardingImageTwo.webp'
import onboardingImageThree from '../../../constants/onboardingImages/onboardingImageThree.webp'
const Step1 = ({ currentImageStep }: any) => {
  const isXs = useMediaQuery('(max-height:750px)');
  const renderStepImage = (src: string) => (
    <img
    src={src}
    alt={`Step${currentImageStep}`}
    style={{
      width: '100%',
      height: isXs?'245px':'100%',
      maxHeight: '100%',
    }}
  />
  );
  return (
    <Box sx={Style.mainBox}>
      <Box sx={Style.headingBox}>
        <Typography sx={Style.step}>step 1</Typography>
        <Typography sx={Style.title}>Get to know DOT</Typography>
        <Typography sx={Style.description}>Learn the basics in under 1 minute</Typography>
      </Box>
      <Box sx={Style.videoBox}>
        {currentImageStep === 1 && renderStepImage(onboardingImageOne)}
        {currentImageStep === 2 && renderStepImage(onboardingImageTwo)}
        {currentImageStep === 3 && renderStepImage(onboardingImageThree)}
        {currentImageStep === 4 && renderStepImage(onboardingImageFour)}
        {currentImageStep === 5 && renderStepImage(onboardingImageFive)}
      </Box>
    </Box>
  )
}
export default Step1


const Style = {
  mainBox: {
    height: '100%',
    display: 'flex',
    padding: '32px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch'
  },
  headingBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch'
  },
  step: {
    color: 'var(--text-icons-light-base-second, #858C95)',

    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.12px',
    textTransform: 'uppercase'
  },
  title: {
    color: 'var(--text-icons-light-base-main, #323539)',

    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: '-0.2px'
  },
  description: {
    color: 'var(--text-icons-light-base-second, #858C95)',

    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.16px'
  },
  videoBox: {
    height: '70%',
    alignSelf: 'stretch',
    width: '100%'
  },
}

