import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import uploadIcon from '../../../assets/uploadIcon.webp'
import whiteUploadIcon from '../../../assets/whiteUploadIcon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { storage } from '../../../services/firebase'
import { BucketType, getBucketName } from '../../../util/constants'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { loadingStart, loadingStop, notificationFail } from '../../../store/actions'
import { handleImageInputClick } from '../../../util/fileInputs'
import CircularProgress from '@mui/material/CircularProgress'
import { analytics_screenshot_upload_completed, analytics_screenshot_upload_initiated } from 'services/mixpanel'

const Step4 = ({ websiteData, setWebsiteData }: any) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const [isUploading, setIsUploading] = useState(false)
  const [showUpload, setShowUpload] = useState(false) // New state variable
  const dispatch = useDispatch()
  const client = useSelector((state: any) => state.apolloClient.client)
  const userDetails = useSelector((state: any) => state.settingReducer.userDetails)
  const storageRef = ref(storage, `${getBucketName(userDetails?._id, BucketType.WEBSITE)}/${Date.now()}`);  
  const [isDragging, setIsDragging] = useState(false)

  const handleImageUpload = async (file: any) => {
    const imageFile = [file]
    try {
      dispatch(loadingStart() as any)
      setIsUploading(true) // Set isUploading to true while uploading
      analytics_screenshot_upload_initiated({})
      if (imageFile[0]) {
        const uploadTask: any = uploadBytesResumable(storageRef, imageFile[0])

        uploadTask.on(
          'state_changed',
          (snapshot: any) => {},
          (err: any) => {
            dispatch(notificationFail(err, err.message) as any)
            dispatch(loadingStop() as any)
            setIsUploading(false) // Set isUploading back to false on error
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              setSelectedImage(url)
              setIsUploading(false) // Set isUploading back to false after successful upload
              setShowUpload(false)
              setWebsiteData({ ...websiteData, screen_shot_url: url })
              analytics_screenshot_upload_completed({})
            })
          }
        )
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message) as any)
      dispatch(loadingStop() as any)
      setIsUploading(false) // Set isUploading back to false on error
    }
  }

  const handleDragOver = (e: any) => {
    e.preventDefault()
    setIsDragging(true) // Set isDragging to true when a file is dragged over the area
  }

  const handleDragLeave = () => {
    setIsDragging(false) // Set isDragging back to false when a file leaves the area
  }

  const handleDrop = (e: any) => {
    e.preventDefault()
    setIsDragging(false) // Reset isDragging

    // Get the dropped file from the event
    const droppedFile = e.dataTransfer.files[0]
    if (droppedFile) {
      handleImageUpload(droppedFile) // Call the image upload function with the dropped file
    }
  }
  const showUploadIcon = () => {
    if (showUpload) {
      setShowUpload(false)
    } else {
      setShowUpload(true)
    }
  }
  return (
    <Box sx={Style.mainBox}>
      <Box sx={Style.headingBox}>
        <Typography sx={Style.step}>step 4</Typography>
        <Typography sx={Style.title}>Authenticate Your Website Traffic.</Typography>
        <Typography sx={Style.description}>
          Upload a screenshot of your analytics (such as Google Analytics).
        </Typography>
        <Typography sx={Style.warning} style={{ display: selectedImage ? 'none' : 'flex' }}>
          ⚠️ 30-day history including traffic by country
        </Typography>
      </Box>
      <Box
        sx={Style.uploadBox}
        style={{
          border: selectedImage
            ? '1.288px solid var(--background-light-primary-second, #F1F0F6)'
            : '1px dashed var(--stroke-light-base, #E5E5E7)',
          padding: selectedImage ? '0px' : '24px',
        }}
        onDragOver={handleDragOver} // Add drag over event listener
        onDragLeave={handleDragLeave} // Add drag leave event listener
        onDrop={handleDrop} // Add drop event listener
      >
        {isUploading ? (
          <CircularProgress style={{ position: 'absolute', top: '40%',  left: '45%', zIndex: 1000 }} />
        ) : selectedImage ? (
          <div style={{ position: 'relative', cursor: 'pointer',width: '100%', height: '100%' ,border:"2px solid black" }}>
            <img
              src={selectedImage}
              alt="SelectedImage"
              style={{ width: '100%',maxHeight:"100%",maxWidth:"100%" ,height: '100%',objectFit:"contain" ,filter: showUpload ? 'brightness(100%)' : 'none' }}
              onClick={showUploadIcon}
            />
            <Box
              sx={Style.overlay}
              onClick={() => setShowUpload(false)}
              style={{ display: showUpload ? 'block' : 'none' }}
            ></Box>
            <Box
              style={{
                display: showUpload ? 'flex' : 'none',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
              onClick={() => handleImageInputClick('fileInput')}
            >
              <input
                type="file"
                accept="image/*"
                id="fileInput"
                onChange={(e: any) => handleImageUpload(e.target.files[0])}
                style={{ display: 'none' }}
              />
              <Box sx={Style.whiteIconBox} style={{ width: '40px', margin: 'auto' }}>
                <img src={whiteUploadIcon} alt="uploadIcon" style={{ cursor: 'pointer' }} />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Typography sx={{ ...Style.dropFile, color: 'var(--text-icons-light-on-color, #FFF)' }}>
                  Upload again
                </Typography>
                <Typography sx={{ ...Style.fileType, color: 'var(--text-icons-light-on-color, #FFF)' }}>
                  JPG, PNG or SVG
                </Typography>
              </Box>
            </Box>
          </div>
        ) : (
          <Box
            sx={{ display: 'flex', gap: '16px', flexDirection: 'column' }}
            onClick={() => handleImageInputClick('fileInput')}
          >
            <input
              type="file"
              accept="image/*"
              id="fileInput"
              onChange={(e: any) => handleImageUpload(e.target.files[0])}
              style={{ display: 'none' }}
            />
            <Box sx={Style.iconBox} style={{ width: '40px', margin: 'auto' }}>
              <img src={uploadIcon} alt="uploadIcon" style={{ cursor: 'pointer' }} />
            </Box>
            <Box>
              <Typography sx={Style.dropFile}>Drop your files here</Typography>
              <Typography sx={Style.fileType}>JPG, PNG or SVG</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const Style = {
  mainBox: {
    height:"100%",
    display: 'flex',
    padding: '32px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch'
  },
  headingBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch'
  },
  step: {
    color: 'var(--text-icons-light-base-second, #858C95)',

    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.12px',
    textTransform: 'uppercase'
  },
  title: {
    color: 'var(--text-icons-light-base-main, #323539)',

    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: '-0.2px'
  },
  description: {
    color: 'var(--text-icons-light-base-second, #858C95)',

    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.16px'
  },
  warning: {
    color: '#6F3A14',

    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '-0.1px',
    display: 'flex',
    padding: 'var(--main-system-2-px, 2px) var(--main-system-10-px, 10px)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--main-system-4-px, 4px)',
    borderRadius: 'var(--roundness-full-inside, 128px)',
    background: '#FFF0E1'
  },
  uploadBox: {
    maxHeight:"250px",
    height:"100%",
    width:"100%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    // alignSelf: 'stretch',
    borderRadius: '10px',
    background: 'var(--background-light-base-main, #FFF)',
    position: 'relative'
  },
  iconBox: {
    display: 'flex',
    padding: 'var(--main-system-8-px, 8px)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'var(--roundness-full-inside, 128px)',
    border: '1px solid var(--stroke-light-base, #E5E5E7)',
    background: 'var(--background-light-base-main, #FFF)',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.04)'
  },
  whiteIconBox: {
    display: 'flex',
    padding: 'var(--main-system-8-px, 8px)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'var(--roundness-full-inside, 128px)',
    border: '1px solid var(--stroke-light-base, #E5E5E7)',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.04)'
  },
  fileType: {
    color: 'var(--text-icons-light-base-second, #858C95)',
    textAlign: 'center',

    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.1px'
  },
  dropFile: {
    color: 'var(--text-icons-light-base-main, #323539)',

    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '22px'
  },
  overlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.50)'
  }
}

export default Step4
