import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, TextField, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import React from 'react'
import { useDispatch } from 'react-redux'
import { EmailOutlined } from '@mui/icons-material'
import { IoArrowBackCircleOutline } from 'react-icons/io5'
import { forgotPassword } from '../../store/actions/auth'

const validateEmail =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

export default function ForgotPasswordDialog(props: any) {
  const { open, handleClose,backToLogin } = props
  const dispatch: any = useDispatch()
  const [disableButton, setDisableButton] = React.useState(true)
  const [email, setEmail] = React.useState('')

  const handlePasswordReset = async (email: string) => {
    if (email) {
      dispatch(forgotPassword(email, backToLogin))
    } else {
      alert('Please enter your email address')
    }
  }

  const handleEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
    const email = event.currentTarget.value
    setEmail(email)
    if (validateEmail.test(email)) {
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
  }

  return (
    <Dialog id='forgotPasswordDialog' open={open}>
      <Box sx={Style.dialogBox}>
        <Box sx={Style.normalBox}>
          <Typography sx={Style.heading}>Reset Password</Typography>
          <Typography sx={Style.description}>Enter your email to reset your password</Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={Style.normalBox}>
          <Typography sx={Style.email}>Email Address</Typography>
          <TextField
            fullWidth
            onChange={(event: any) => handleEmailChange(event)}
            placeholder="Email"
            InputProps={{
              endAdornment: (
                <IconButton>
                  <EmailOutlined />
                </IconButton>
              )
            }}
          />
        </Box>
        <Button
          variant="contained"
          disabled={disableButton}
          onClick={() => handlePasswordReset(email)}
          sx={{
            width: '100% !important',
            height: '40px',
            color: '#fff !important',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          Continue
        </Button>
        <Box onClick={backToLogin} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IconButton>
            <IoArrowBackCircleOutline />
          </IconButton>
          <Typography sx={Style.footerTypography}>Go back to log in</Typography>
        </Box>
      </Box>
    </Dialog>
  )
}
const Style = {
  dialogBox: {
    display: 'flex',
    width: { xs: '100%', sm: '512px' },
    padding: { xs: '32px 16px', md: '32px' },
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '32px',
    flexShrink: 0,
    borderRadius: '10px',
    background: 'var(--base-white, #FFF)',
    margin: 'auto'
  },
  normalBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'center',
    gap: '8px',
    alignSelf: 'stretch'
  },
  heading: {
    color: 'var(--gray-700, var(--gray-700, #323539))',
    textAlign: 'center',

    fontSize: '36px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '44px',
    letterSpacing: '-0.36px',
    alignSelf: 'stretch'
  },
  description: {
    color: 'var(--gray-50, var(--gray-50, #858C95))',
    textAlign: 'center',

    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '-0.18px',
    alignSelf: 'stretch'
  },
  email: {
    textAlign: 'left',
    color: 'var(--text-icons-light-base-main, #323539)',

    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.1px',
    alignSelf: 'stretch'
  },
  footerTypography: {
    color: 'var(--background-light-primary-main, #5828C4)',

    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    pointer: 'cursor'
  }
}
