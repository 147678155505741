import * as DotGQL from '../../../services/graphQL'
import { loadingStart, loadingStop, notificationFail, notificationSuccess } from "../customization"
import * as actionTypes from '../../actionTypes'
import { NOTIFICATIONS } from '../../../util/notification'
// import {SET_ACTIVE_COUNT,SET_REJECTED_COUNT,SET_PENDING_COUNT, } from '../../actionTypes';

export const addWebsite = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.addWebsite,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((value: any) => {
        dispatch(loadingStop())
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.addWebsiteRequestedAdded))
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.websiteAddFailed, error.message))
      })
  }
}
          

export const addWebsiteWithCallBack = (client: any, req: any, callback: () => void) => {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(loadingStart());
      client.mutate({
        mutation: DotGQL.addWebsite,
        fetchPolicy: 'no-cache',
        variables: req
      }).then((value: any) => {
        callback();
        dispatch(loadingStop());
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.addWebsiteRequestedAdded));
        resolve(value);
      }).catch((error: any) => {
        dispatch(loadingStop());
        dispatch(notificationFail(NOTIFICATIONS.ERROR.websiteAddFailed, error.message));
        reject(error);
      });
    });
  };
};


export const getWebsiteList = (client: any, req: any) => {
  return async (dispatch: any) => {
    dispatch(loadingStart());
    try {
      const result = await client.query({
        query: DotGQL.getWebsiteList,
        fetchPolicy: 'no-cache',
        variables: req
      });
      dispatch({
        type: actionTypes.WEBSITE_LIST,
        payload: result?.data?.viewWebsiteList?.websites
      });

      dispatch({
        type: actionTypes.SET_TOTAL_WEBSITE_COUNT,
        payload: result?.data?.viewWebsiteList?.total_records
      });

      dispatch(loadingStop());

    } catch (error: any) {
      dispatch(loadingStop());
      dispatch(notificationFail(NOTIFICATIONS.ERROR.campaignListFail, error.message));
      throw error; // Re-throw the error so it can be caught by the calling code
    }
  };
};

export const getWebsiteListToExport = (client: any, req: any) => {
  return async (dispatch: any) => {
    dispatch(loadingStart());

    try {
      const result = await client.query({
        query: DotGQL.getWebsiteList,
        fetchPolicy: 'no-cache',
        variables: req
      });

      dispatch({
        type: actionTypes.EXPORT_WEBSITE_LIST,
        payload: result?.data?.viewWebsiteList?.websites
      });

      dispatch(loadingStop());

    } catch (error: any) {
      dispatch(loadingStop());
      dispatch(notificationFail(NOTIFICATIONS.ERROR.campaignListFail, error.message));
      throw error; // Re-throw the error so it can be caught by the calling code
    }
  };
};

export const updateWebsiteStatusFilter = (value: any) => {
  return {
    type: actionTypes.SET_SELECTED_FILTER,
    payload: value
  }
}

export const websiteSearchFilter = (value: any) => {
  return {
    type: actionTypes.SET_SEARCH_FILTER,
    payload: value
  }
}


export const setExportCSV = (value: any) => {
  return {
    type: actionTypes.SET_EXPORT_CSV,
    payload: value
  }
}


export const getWebsiteCounts = (client: any) => {
  return async (dispatch: any) => {
    dispatch(loadingStart());

    try {
      const result = await client.query({
        query: DotGQL.getWebsiteCounts,
        fetchPolicy: 'no-cache',
      });
      dispatch({
        type: actionTypes.SET_WEBSITE_COUNT,
        payload: result?.data?.getWebsiteCounts
      });

      dispatch(loadingStop());

    } catch (error: any) {
      dispatch(loadingStop());
      dispatch(notificationFail(NOTIFICATIONS.ERROR.websiteCountsFetchFailed, error.message));
      throw error;
    }
  };
};

export const getRevenueDetails = (client: any, req: any) => {
  return async (dispatch: any) => {
    dispatch(loadingStart());

    try {
      const result = await client.query({
        query: DotGQL.getRevenueDetails,
        fetchPolicy: 'no-cache',
        variables: req
      });
      dispatch({
        type: actionTypes.SET_REVENUE_DETAILS,
        payload: result?.data?.revenueDetail
      });

      dispatch(loadingStop());

    } catch (error: any) {
      dispatch(loadingStop());
      dispatch(notificationFail(NOTIFICATIONS.ERROR.websiteCountsFetchFailed, error.message));
      throw error;
    }
  };
};

export const getEarningsBreakdown = (client: any, req: any) => {
  return async (dispatch: any) => {
    dispatch(loadingStart());
    try {
      const result = await client.query({
        query: DotGQL.getEarningsBreakdown,
        fetchPolicy: 'no-cache',
        variables: req
      });
      dispatch({
        type: actionTypes.SET_EARNING_BREAKDOWN,
        payload: result?.data?.earningsBreakdown
      });
      
      dispatch(loadingStop());

    } catch (error: any) {
      dispatch(loadingStop());
      dispatch(notificationFail(NOTIFICATIONS.ERROR.websiteCountsFetchFailed, error.message));
      throw error;
    }
  };
};
export const selectedRevenueWebsiteFilter = (value: any) => {
  return {
    type: actionTypes.SET_REVENUE_WEBSITES,
    payload: value
  }
}
export const selectedRevenueCountriesFilter = (value: any) => {
  return {
    type: actionTypes.SET_REVENUE_COUNTRIES,
    payload: value
  }
}
export const selectedRevenueTimeFilter = (value: any) => {
  return {
    type: actionTypes.SET_REVENUE_TIME,
    payload: value
  }
}
