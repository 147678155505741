import { Box, Theme, useMediaQuery } from '@mui/material'
import './style/flexboxgrid.min.css'
import './style/index.css'
import Landing from './Landing'

import { useGoogleOneTapLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux'
import { notificationFail } from 'store/actions';
import { NOTIFICATIONS } from 'util/notification';
import { oneTaplogin } from 'store/actions/auth';
import { PROMPT_PARENT_ID } from 'services/googleOneTapLogin/config';
// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  useGoogleOneTapLogin({
    onSuccess: credentialResponse => {
      dispatch(oneTaplogin(credentialResponse) as any)
    },
    onError: () => {
      dispatch(notificationFail(NOTIFICATIONS.ERROR.someThingWrong) as any);
    },
    auto_select: true,
    prompt_parent_id: isMobile ? undefined : PROMPT_PARENT_ID // Conditionally set prompt_parent_id
  });

  return (
    <Box>
      <Landing />
    </Box>
  )
}

export default MinimalLayout
