const redirect_url_prod = 'https://publishers.dotaudiences.com/?emailVerified=true&email=';
const redirect_url_dev = 'https://publisher-dashboard-dev-t6a9w.ondigitalocean.app/?emailVerified=true&email=';
const redirect_url_local = 'http://localhost:3000/?emailVerified=true&email=';

export const servicesConfig = {
    firebase: {
        redirect_url: process.env.NODE_ENV === 'production' ? redirect_url_prod
            : process.env.NODE_ENV === 'development' ? redirect_url_dev
                : redirect_url_local
    }
};
