import Cookies from 'js-cookie'
import { UserType } from '../../util/constants'
import * as actionTypes from '../actionTypes'
import { updateObject } from '../shared/utility'
import { getEncryptedCookie } from '../../services/encryption.ts'

interface actionType {
  type: string
  payload: any
}

// setting initial state after checking local storage
const getInitialState = () => {
  const userToken = Cookies.get('dot-pub-token')
  const userType = getEncryptedCookie('pub-settings')

  if (userToken && userType) {
    return {
      idToken: userToken,
      isLoggedIn: true,
      loading: false,
      userType: userType,
      isNewUser: false,
      error: null
    }
  } else {
    Cookies.remove('dot-pub-token')
    Cookies.remove('pub-settings')
    return {
      idToken: null,
      isLoggedIn: false,
      loading: false,
      userType: UserType.CUSTOMER,
      isNewUser: false,
      error: null
    }
  }
}

const initialState = getInitialState()

const reducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case actionTypes.LOGIN_USER_SUCCESS:
      return updateObject(state, {
        user: action.payload?._id,
        idToken: action.payload?._token,
        userType: action.payload?.user_type,
        isNewUser: action.payload?.new_user,
        isLoggedIn: true,
        loading: false,
        error: null
      })

    case actionTypes.SETS_IS_NEW_USER:
      return updateObject(state, {
        isNewUser: action.payload?.new_user
      })

    case actionTypes.LOGIN_USER_FAILURE:
      return updateObject(state, {
        loading: false,
        isLoggedIn: false,
        error: action.payload
      })

    case actionTypes.SIGN_UP_USER_SUCCESS:
      return updateObject(state, {
        user: null,
        isLoggedIn: false,
        loading: false,
        error: null
      })

    case actionTypes.SETTING_USER_FAILURE:
      return updateObject(state, {
        loading: false,
        isLoggedIn: false,
        error: action.payload
      })

    case actionTypes.LOGOUT_USER_SUCCESS:
      return updateObject(state, {
        idToken: null,
        isLoggedIn: false,
        loading: false,
        userType: null,
        error: null,
        user: null
      })

    case actionTypes.RESET_PASSWORD_SENT_FAILURE:
      return updateObject(state, {
        loading: false,
        error: action.payload
      })

    default:
      return state
  }
}

export default reducer
