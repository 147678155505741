import { Box, Divider, Grid, List, ListItem, Typography } from '@mui/material'
// import { useTheme } from '@mui/material/styles'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-scroll'
import FooterLogo from '../FooterLogo'
import {
  DOT_ADS,
  DOT_PR_MARKETPLACE,
  HELP_DOT_AUDIENCES,
  PRIVACY_POLICY,
  TERMS_OF_SERVICE
} from '../../../../../constants/redirectLinks'
import AuthModal from '../../../../../views/modals/AuthDialog'

export default function TopFooter() {
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(2)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const setStepValue = (value: any) => {
    setStep(value)
  }

  // const theme: any = useTheme()

  const node: any = useRef(null)
  // const src = process.env.REACT_APP_DOT_SUBSCRIPTION_LINK
  // useEffect(() => {
  //   if (node) {
  //     const script: any = document.createElement('script')
  //     script.src = src
  //     script.async = true
  //     script.setAttribute('data-uid', 'f9c4c7dc82')
  //     node?.current?.appendChild(script)
  //   }
  //   // eslint-disable-next-line
  // }, [node])

  return (
    <Box>
      <Grid container sx={{ padding: { xs: '50px 0px 0px', md: '110px 0px 40px' } }}>
        <Grid item xs={12} md={4} sx={{ pr: { xs: '0', md: 4 }, mb: { xs: '0px', md: 0 } }}>
          <Box>
            <FooterLogo />
            <Typography
              sx={{
                fontSize: { xs: '14px', md: '15px' },
                fontWeight: { xs: 600, md: 500 },
                lineHeight: { xs: '20px', md: '22px' },
                letterSpacing: { xs: '-0.1px' },
                mb: '36px',
                color: '#2D146F'
              }}
            >
              🌶️ Spice up your earnings
            </Typography>

            <div ref={node}></div>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Typography sx={Style.sectionHeader}>Get Started</Typography>
              <List>
                <ListItem className="footer-item" sx={Style.sectionItem}>
                  <Link
                    offset={-60}
                    activeClass="active"
                    to="Home"
                    spy={true}
                    smooth={true}
                    style={{ cursor: 'pointer' }}
                  >
                    <Typography
                      onClick={(props) => {
                        setStep(2)
                        handleClickOpen()
                      }}
                      id='footer-createAccount'
                      sx={Style.sectionItemPopUps}
                    >
                      Create Account
                    </Typography>
                  </Link>
                </ListItem>
                <ListItem className="footer-item" sx={Style.sectionItem}>
                  <Link
                    offset={-60}
                    activeClass="active"
                    to="Home"
                    spy={true}
                    smooth={true}
                    style={{ cursor: 'pointer' }}
                  >
                    <Typography
                      onClick={(props) => {
                        setStep(1)
                        handleClickOpen()
                      }}
                      id='footer-login'
                      sx={Style.sectionItemPopUps}
                    >
                      Login
                    </Typography>
                  </Link>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography sx={Style.sectionHeader}>Get Support</Typography>
              <List>
                <ListItem className="footer-item" sx={Style.sectionItem}>
                  <a href={HELP_DOT_AUDIENCES} id='footer-knowledgeBase' target="_blank" rel="noreferrer">
                    Knowledge Base
                  </a>
                </ListItem>
                <ListItem className="footer-item" sx={Style.sectionItem}>
                  <a href={TERMS_OF_SERVICE} id='footer-termsOfService' target="_blank" rel="noreferrer">
                    Terms of Service
                  </a>
                </ListItem>
                <ListItem className="footer-item" sx={Style.sectionItem}>
                  <a href={PRIVACY_POLICY} id='footer-privacyPolicy' target="_blank" rel="noreferrer">
                    Privacy Policy
                  </a>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={Style.sectionHeader}>Advertisers</Typography>
              <List>
                <ListItem className="footer-item" sx={Style.sectionItem}>
                  <a href={DOT_ADS} id='footer-dotAds' target="_blank" rel="noreferrer">
                    DOT Ads
                  </a>
                </ListItem>
                <ListItem className="footer-item" sx={Style.sectionItem}>
                  <a href={DOT_PR_MARKETPLACE} id='footer-dotPRMarketplace' target="_blank" rel="noreferrer">
                    DOT PR Marketplace
                  </a>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ borderColor: '#000000', opacity: '0.1', mt: { xs: '0', md: 3 } }} />
      {open && <AuthModal open={open} handleClose={handleClose} step={step} setStepValue={setStepValue} />}
    </Box>
  )
}

const Style = {
  sectionHeader: {
    color: '#12141D',
    fontWeight: '600',
    lineHeight: '24px',
    letterSpacing: '-0.16px'
  },
  sectionItem: {
    color: 'var(--text-icons-light-base-second, #858C95)',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '-0.16px',
    pl: 0
  },
  sectionItemPopUps: {
    color: 'var(--text-icons-light-base-second, #0b093b)',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '-0.16px',
    pl: 0,
    '&:hover': {
      color: 'purple'
    }
  }
}
