import { Box, Typography, Grid, useMediaQuery } from '@mui/material'
import { useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SUPPORT_GOOGLE_AD_SENSE } from '../../../../constants/redirectLinks'
const questions = [
  {
    id: '1',
    question: 'What is DOT Fusion AI?',
    answer:
      `<p>Simply put, Fusion AI is the name of the intelligent technology powering the highest possible on page revenue, whilst keeping page speeds lightening fast.<p/>
      <br>
      <p>Fusion AI combines multiple demand sources in real time, maximising earnings across all geo's and devices. Fusion AI does not require any additional set up, and is included automatically with our on page tags.<p/>`
  },
  {
    id: '2',
    question: 'What are the payout options?',
    answer:
      "Several, you've earned it! We offer fast payouts via Bank Transfer, Payoneer, Paypal, Bitcoin, and USDT (ERC20)"
  },
  {
    id: '3',
    question: 'Can I keep my existing networks?',
    answer:
      'Yes, absolutely! You are free to find the right mix of ad sizes and units from DOT, or any other provider. You are in control.'
  },
  {
    id: '4',
    question: 'What are the requirements to join?',
    answer: `<p>A really common query is - what are the conditions that my site must meet to be approved.
    All sites must comply with <a href="${SUPPORT_GOOGLE_AD_SENSE}" target="_blank">Google Policies</a>. <p/>
    </br>
    <p>We work with informational, content rich sites. We don't currently work with sites that are predominantly corporate or ecommerce. However, for example, if your site is a blog attached to an e-commerce site - feel free to submit it for approval and
    we can take a look!<p/>
    </br>
    <p>As a general rule, the site must have in excess of 100k monthly page views, but again, if your site offers quality content and
    you'd like us to check, feel free to create an account and submit the site for approval. We'll let you know within 3-5 business
    days.<p/>`
  },
  {
    id: '5',
    question: 'Will this slow down my website?',
    answer:
      'Never. We understand page speed is important for both user experience and monetisation. DOT Fusion AI has been designed to be ultra resource light, even on the slowest of connections.'
  },
  {
    id: '6',
    question: 'How easy is the integration process?',
    answer:
      "Once your site approved, simply let us know which ad sizes you'd like and we'll prepare them for you. We offer complete support during and after set up but usually integration takes just 5 - 10 minutes."
  }
]
const FrequentlyAskedQuestions = () => {
  const [expandedItems, setExpandedItems] = useState(questions.map(() => false))

  const Screen = useMediaQuery('(max-width:900px)')
  const xsDescription = 'Our FAQ area is the best place to look to find answers to your questions.'

  const handleExpand = (index: any) => {
    const newExpandedItems = [...expandedItems]
    newExpandedItems[index] = !newExpandedItems[index]
    setExpandedItems(newExpandedItems)
  }

  // const filteredQuestions = Screen ? questions.slice(0, 3) : questions

  return (
    <Box sx={Style.mainBox} id="Faq">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', mb: '26px' }}>
        <Typography sx={Style.heading}  className="faq-heading">Frequently Asked Questions</Typography>
        {Screen && <Typography sx={Style.subHeading}>{xsDescription}</Typography>}
      </Box>
      <Grid container spacing={2}>
        {questions.map((question, index) => (
          <Grid item key={question.id} xs={12} md={6}>
            <Box sx={Style.questionBox} className="faq-question-box">
              <Accordion expanded={expandedItems[index]} onChange={() => handleExpand(index)} sx={Style.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={Style.question}>{question.question}</Typography>
                </AccordionSummary>
                <div style={{ padding: '10px 16px', maxHeight: '125px', overflowY: 'auto' }}>
                <Typography dangerouslySetInnerHTML={{ __html: question.answer }} />
                </div>
              </Accordion>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
const Style = {
  mainBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: { xs: '24px', md: '64px' },
    background: 'var(--background-light-base-second, #F8F9FB)',
    // padding: { xs: '16px', md: '64px' }
    padding: {
      xs: '10px 15px',
      md: '64px 112px'
    }
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    alignSelf: 'stretch',
    color: 'var(--text-icons-light-base-main, #323539)',
    textAlign: 'center',

    fontSize: { xs: '24px', md: '32px' },
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: { xs: '32px', md: '40px' },
    letterSpacing: '-0.36px',
    margin: 'auto',
    mt: { xs: '40px', md: 0 }
  },
  subHeading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: 'var(--text-icons-light-base-second, #858C95)',
    fontStyle: 'normal',
    margin: 'auto',
    fontWeight: 400,
    lineHeight: { xs: '16px', md: '16px' },
    letterSpacing: '-0.16px',
    textAlign: 'center'
  },
  questionBox: {
    display: 'flex',
    height: { xs: 'auto', md: 'auto' },
    width: '100%',
    alignItems: 'center',
    marginBottom: '10px',
    borderRadius: 'var(--roundness-round-inside, 6px)',
    border: '1px solid var(--stroke-light-base, #E5E5E7)',
    boxShadow: '0px 4px 8px 0px rgba(113, 128, 150, 0.08), 0px 0px 1px 0px rgba(113, 128, 150, 0.04)'
  },
  question: {
    color: 'var(--text-icons-light-base-main, #323539)',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.16px',
    flex: '1 0 0'
  },
  accordion: {
    width: '100%',
    padding: '10px'
  }
}

export default FrequentlyAskedQuestions
