import React, { useState } from 'react'
import { List, ListItem, ListItemIcon, ListItemText, Typography, Button, Box, Grid, Card } from '@mui/material'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import dotWhiteLogo from '../../../assets/dotWhiteLogo.svg'
import successTick from '../../../assets/successTick.svg'
import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'
import Step4 from './step4'
import Step5 from './step5'
import { useNavigate } from 'react-router'
import { steps } from './stepsData'
import { DOT_REACH_OUT_MAIL, TERMS_OF_SERVICE } from '../../../constants/redirectLinks'
import { LinkWithUrl } from '../../../util/links'
import { useDispatch, useSelector } from 'react-redux'
import { editUser } from '../../../store/actions/setting'
import { addWebsite } from '../../../store/actions/user'
import {
  add_new_website_step1_completed,
  add_new_website_step2_completed,
  add_new_website_step3_completed,
  add_new_website_step4_completed,
  new_website_submited,
  onboarding_image_sequence_completed
} from 'services/mixpanel'
import { sendNewWebsiteSubmitedEventToFbPixel } from 'services/facebookPixel'
import { sendNewWebsiteSubmitedEventToGTM } from 'services/googleTagManager'

type WebsiteData = {
  name: string
  screen_shot_url: string
}

const Onboarding: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const client = useSelector((state: any) => state.apolloClient.client)

  const [currentStep, setCurrentStep] = useState<number>(0)
  const [currentImageStep, setCurrentImageStep] = useState(1)
  const [websiteData, setWebsiteData] = useState<WebsiteData>({ name: '', screen_shot_url: '' })
  const [isValid, setIsValid] = useState<boolean>(true)

  const validateUrl = (url: string) => {
    const pattern = new RegExp(
      '^(?!.*(?:\\bhttps?:\\/\\/|\\bhttps?\\.|\\bhttp:\\/\\/|\\bhttp\\.|\\bhttps\\.|\\bhttp$|\\bhttps$))' +
      '(?:www\\.)?' +
      '(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\\.)+' +
      '[a-zA-Z]{2,}(?:\\.[a-zA-Z]{2,})?' +
      '(?:[/?#]\\S*)?$',
      'i'
         );
    //  "previous pattern"
    //   '^(?:https?://)?(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\\.)+[a-zA-Z]{2,}(?:\\.[a-zA-Z]{2,})?(?:[/?#]\\S*)?$',
    //   'i'
    setIsValid(pattern.test(url))
  }

  const sendAddWebsiteRequest = () => {
    let params = {
      name: 'https://' + websiteData.name,
      screen_shot_url: websiteData.screen_shot_url
    }
    dispatch(addWebsite(client, params) as any)
  }

  const setIsNewUserToFalse = () => {
    dispatch(editUser(client, { new_user: false }) as any)
  }

  const sendAnalyticsEvents = () => {
    // mixpanel signal
    new_website_submited({
      domain_name: websiteData?.name
    })
    // fbPixel
    sendNewWebsiteSubmitedEventToFbPixel({
      domain_name: websiteData?.name
    })
    // google Data Layer
    sendNewWebsiteSubmitedEventToGTM({
      domain_name: websiteData?.name
    })
  }


  const handleNext = () => {
    if (currentStep === 0 && currentImageStep < 5) {
      // Increase the current image step
      setCurrentImageStep(currentImageStep + 1)
    } else {
      switch (currentStep) {
        // when image sequencing ends
        case 0:
          add_new_website_step1_completed({})
          onboarding_image_sequence_completed({})
          setCurrentStep(currentStep + 1)
          break
        case 1:
          add_new_website_step2_completed({})
          setCurrentStep(currentStep + 1)
          break
        // when user enters website name and presses next
        case 2:
          add_new_website_step3_completed({})
          setCurrentStep(currentStep + 1)
          break
        // when user gives the analytics screenshot
        case 3: // Step 3 in a 0-based index
          sendAddWebsiteRequest()
          add_new_website_step4_completed({})
          setCurrentStep(currentStep + 1)
          break
        case 4:
          sendAnalyticsEvents()
          setIsNewUserToFalse()
          navigate('/')
          break
        case steps.length - 1: // The last step
          setIsNewUserToFalse()
          navigate('/')
          break
      }
    }
  }

  const disableNext = (): boolean => {
    switch (currentStep) {
      case 2:
        return !isValid || !websiteData?.name
      case 3:
        return !websiteData?.screen_shot_url
      default:
        return false
    }
  }

  const getButtonLabel = (): string => {
    return currentStep === steps.length - 1 ? 'Finish' : 'Next'
  }

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    } else if (currentStep === 0 && currentImageStep > 1) {
      setCurrentImageStep(currentImageStep - 1)
    }
  }

  const components = [
    <Step1 currentImageStep={currentImageStep} />,
    <Step2 setCurrentImageStep={setCurrentImageStep} />,
    <Step3 setWebsiteData={setWebsiteData} websiteData={websiteData} validateUrl={validateUrl} />,
    <Step4 websiteData={websiteData} setWebsiteData={setWebsiteData} />,
    <Step5 />
  ]

  return (
    <Grid container sx={{ minHeight: '100vh', paddingBottom: 'env(safe-area-inset-bottom)' }}>
      {/* -------------------------   Grid Item to display the Sidebar  --------------------------- */}
      <Grid item md={4} xs={12}>
        <Box sx={Style.sideBarBox}>
          <List sx={Style.sideBarNavBox}>
            <Box sx={Style.logoBox}>
              <img src={dotWhiteLogo} alt="dotLogo" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'row', md: 'column' } }}>
              {steps.map((step, index) => (
                <ListItem key={index} sx={Style.sideBarNavItem}>
                  <Box sx={Style.stepNumberBox}>
                    <ListItemIcon
                      sx={Style.iconBox}
                      style={{
                        background:
                          currentStep === 4
                            ? '#00DB96'
                            : index === currentStep
                              ? 'var(--base-white, #FFF)'
                              : index < currentStep
                                ? '#00DB96'
                                : 'none'
                      }}
                    >
                      {index < currentStep || currentStep === 4 ? (
                        <img src={successTick} alt="successIcon" />
                      ) : (
                        <Typography
                          sx={Style.stepNumber}
                          style={{
                            color:
                              index === currentStep
                                ? 'var(--background-light-primary-main, #5828C4)'
                                : 'var(--base-white, #FFF)'
                          }}
                        >
                          {index + 1}
                        </Typography>
                      )}
                    </ListItemIcon>
                    <Box sx={Style.line} style={{ display: index === 4 ? 'none' : 'flex' }}></Box>
                  </Box>
                  <ListItemText
                    sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column' }}
                    primary={<Typography sx={Style.primaryText}>{step.title}</Typography>}
                    secondary={<Typography sx={Style.secondaryText}>{step.description}</Typography>}
                  />
                </ListItem>
              ))}
            </Box>
          </List>
          <Box sx={Style.sideBarFooter}>
            {/* Terms of service should be clickable and redirectable */}
            <Typography sx={Style.footerTypography}>
              {
                <LinkWithUrl url={TERMS_OF_SERVICE} style={{ color: 'var(--primary-200, #E9D7FE)' }}>
                  {' '}
                  Terms of Service{' '}
                </LinkWithUrl>
              }
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <MailOutlineIcon sx={{ color: 'var(--primary-200, #E9D7FE)', width: '16px', height: '16px' }} />
              <Typography sx={Style.footerTypography}>{DOT_REACH_OUT_MAIL}</Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      {/* -------------------------   Grid Item to display the steps  --------------------------- */}
      <Grid item md={8} xs={12}>
        <Box sx={Style.stepContainer}>
          <Card sx={Style.innerStepContainer}>
            <Box sx={{ width: '100%', height: { xs: '80%', md: '100%' } }}>{components[currentStep]}</Box>
            <Box sx={Style.buttonBox} style={{ justifyContent: currentStep === 4 ? 'center' : 'space-between' }}>
              <Button
                sx={{
                  ...Style.button,
                  color: 'var(--text-icons-light-base-main, #323539)',
                  background: 'var(--background-light-base-main, #FFF)'
                }}
                onClick={handleBack}
                style={{
                  // visibility: currentStep === 0  ? 'hidden' : 'visible',
                  visibility: currentStep === 0 && currentImageStep === 1 ? 'hidden' : 'visible',
                  display: currentStep === 4 ? 'none' : 'flex'
                }}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
              <Button
                variant="contained"
                sx={Style.button}
                color="primary"
                onClick={handleNext}
                disabled={disableNext()}
              >
                {getButtonLabel()}
                <KeyboardArrowRight />
              </Button>
            </Box>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}
export default Onboarding

const Style = {
  sideBarBox: {
    width: '100%',
    height: { xs: '20vh', md: '100vh' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    background: 'var(--primary-600, #2D146F)'
  },
  logoBox: {
    display: 'flex',
    width: { xs: '100%', md: '192px' },
    height: '48px',
    padding: { md: '9px 95.502px 7px 13.378px' },
    alignItems: 'center',
    justifyContent: { xs: 'center', md: 'flex-start' }
  },
  sideBarNavBox: {
    height: '100%',
    width: { xs: '100%', md: '77%' },
    display: 'flex',
    padding: { xs: '24px 0px', md: '48px' },
    flexDirection: 'column',
    alignItems: { xs: 'center', md: 'flex-start' },
    gap: { xs: 0, md: '80px' }
  },
  sideBarNavItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    height: '60px',
    alignItems: 'flex-start',
    gap: '17px',
    '&.MuiListItem-root': {
      padding: '0px 5px !important'
    }
  },
  sideBarFooter: {
    display: { xs: 'none', md: 'flex' },
    height: '96px',
    padding: '40px',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    alignSelf: 'stretch'
  },
  footerTypography: {
    color: 'var(--primary-200, #E9D7FE)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    whiteSpace: 'nowrap'
  },
  stepNumberBox: {
    display: 'flex',
    flexDirection: { xs: 'row', md: 'column' },
    alignItems: 'center',
    gap: { xs: '8px', md: '4px' },
    alignSelf: 'stretch'
  },
  stepNumber: {
    textAlign: 'center',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.16px'
  },
  line: {
    width: { xs: '20px', md: '2px' },
    background: '#F1F0F6',
    height: { xs: '2px', md: '40px' }
  },
  iconBox: {
    padding: 0,
    margin: 0,
    minWidth: 'unset',
    display: 'flex',
    width: '32px',
    height: '32px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    border: '1px solid var(--base-white, #FFF)'
  },
  primaryText: {
    color: 'var(--base-white, #FFF)',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    whiteSpace: 'nowrap'
  },
  secondaryText: {
    color: 'var(--base-white, #FFF)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    opacity: 0.6,
    whiteSpace: 'nowrap'
  },
  stepContainer: {
    width: '100%',
    height: { xs: '80vh', md: '100vh' },
    display: 'flex',
    alignItems: 'center',
    background: 'var(--background-light-base-second, #F8F9FB)',
    overflowY: { xs: 'auto', md: 'hidden', lg: 'hidden' },
  },
  innerStepContainer: {
    margin: 'auto',
    height: { xs: '70%', md: '80%' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: { xs: 'space-between', md: 'center' },
    width: { xs: '100%', md: '65%' },
    borderRadius: 'var(--roundness-round-outside, 12px)',
    border: '1px solid var(--stroke-light-base, #E5E5E7)',
    background: 'var(--background-light-base-main, #FFF)',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.04)'
  },
  buttonBox: {
    display: 'flex',
    padding: '16px',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    background: 'var(--neutral-50, #FAFBFC)',
    paddingBottom: 'env(safe-area-inset-bottom, 20px)', // Add safe area inset to existing padding
    position: 'sticky', // Optional: if you want the button to stick at the bottom
    // bottom: 0, // Align to the bottom of the viewport
    zIndex: 1000, // To ensure it's on top of other elements
    height:{xs:"65px",md:"90px"}
  },
  button: {
    display: 'flex',
    padding: 'var(--main-system-12-px, 12px) var(--main-system-20-px, 20px)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--main-system-6-px, 6px)',
    borderRadius: 'var(--roundness-round-inside, 6px)',
    border: '1px solid var(--stroke-light-base, #E5E5E7)',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.04)',
    cursor: 'pointer'
  }
}
