import { lazy, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useRoutes } from 'react-router-dom'
import MainLayout from '../layout/MainLayout'
import Loadable from '../layout/ui-components/Loadable'
import { logoutUser } from '../store/actions/auth'
import Cookies from 'js-cookie'

const Revenue = Loadable(lazy(() => import('../views/admin/Revenue')))
const Publisher = Loadable(lazy(() => import('../views/admin/Publishers')))
const Settings = Loadable(lazy(() => import('../views/settings')))
const PageNotFound = Loadable(lazy(() => import('../views/extra/NotFound')))
const Support = Loadable(lazy(() => import('../views/support')))

export default function AdminRoutes() {
  const dispatch: any = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (!Cookies.get('dot-pub-token')) {
      navigate('/')
      dispatch(logoutUser() as any)
    }
  }, [])

  const list = {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <Revenue /> },
      { path: '/revenue', element: <Revenue /> },
      { path: '/publisher', element: <Publisher /> },
      { path: '/settings', element: <Settings /> },
      { path: '/support', element: <Support />},
      { path: '*', element: <PageNotFound /> }
    ]
  }
  return useRoutes([list])
}
