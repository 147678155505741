import {
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
  Checkbox,
  Grid
} from '@mui/material'
import Box from '@mui/material/Box'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { LoginAuthProvider } from '../../types/auth'
import { useSelector } from 'react-redux'
import { LinkWithUrl } from '../../util/links'
import { TERMS_OF_SERVICE } from '../../constants/redirectLinks'

interface propsType {
  step: any
  handleSignUp: (provider: LoginAuthProvider, args?: { email: string; password: string }) => void
  setStepValue: any
}

const SignUpForm = (props: propsType) => {
  const {handleSignUp, setStepValue } = props

  const [checked, setChecked] = useState(false)
  // const isLoading = false
  const { isLoading } = useSelector((state: any) => state.customization)

  const SignUpSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email Required').trim(' '),
    password: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Password Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Confirm Password is required')
  })
  const [isPassword, setIsPassword] = React.useState(true)
  const [isConfirmPassword, setIsConfirmPassword] = React.useState(true)
  const [isSignUpClicked, setIsSignUpClicked] = useState(false)

  const onClickLogin = () => {
    setStepValue(1)
  }

  return (
    <Box>
      <Formik
        initialValues={{
          email: '',
          password: '',
          confirmPassword: ''
        }}
        validationSchema={SignUpSchema}
        onSubmit={(values: { email: any; password: any }) => {
          values = { ...values, email: values.email.trim() }
          if (checked) {
            // signup api login
            handleSignUp(LoginAuthProvider.Email, values)
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm }: any) => (
          <Form style={{ width: '100%', padding: '0px 20px', boxSizing: 'border-box' }}>
            <Grid container spacing={2}>
              <Grid item sx={Style.inputBox} xs={12}>
                <Typography sx={Style.textStyle}>Email Address</Typography>
                <TextField
                  fullWidth
                  id={'email'}
                  placeholder="Insert your email "
                  type={'text'}
                  name={'email'}
                  value={values?.email}
                  onChange={handleChange}
                  error={touched?.email && Boolean(errors?.email)}
                  helperText={touched?.email && errors.email}
                />
                {errors?.email && (
                  <FormHelperText error id="emailError">
                    {errors?.email}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={6} sx={{ ...Style.inputBox }}>
                <Typography sx={Style.textStyle}>Password</Typography>
                <OutlinedInput
                  fullWidth
                  id={'password'}
                  placeholder="Insert your password"
                  type={isPassword ? 'password' : 'text'}
                  name={'password'}
                  value={values?.password}
                  onChange={handleChange}
                  error={touched?.password && Boolean(errors?.password)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        id='togglePasswordVisibilityIcon'
                        onClick={() => setIsPassword(!isPassword)}
                        aria-label="toggle password visibility"
                        onMouseDown={() => setIsPassword(!isPassword)}
                        edge="end"
                      >
                        {isPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors?.password && (
                  <FormHelperText error id="passwordError">
                    {errors?.password}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={6} sx={{ ...Style.inputBox }}>
                <Typography sx={Style.textStyle}>Confirm Password</Typography>
                <OutlinedInput
                  fullWidth
                  id="confirmPassword"
                  placeholder="Confirm password"
                  type={isConfirmPassword ? 'password' : 'text'}
                  name={'confirmPassword'}
                  onChange={handleChange}
                  error={touched?.confirmPassword && Boolean(errors?.confirmPassword)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        id='toggleConfirmPasswordVisibilityIcon'
                        onClick={() => setIsConfirmPassword(!isConfirmPassword)}
                        aria-label="toggle confirm password visibility"
                        onMouseDown={() => setIsConfirmPassword(!isConfirmPassword)}
                        edge="end"
                      >
                        {isConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors?.confirmPassword && (
                  <FormHelperText error id="confirmPasswordError">
                    {errors?.confirmPassword}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>

            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'space-between',
                alignItems: { xs: 'flex-start', md: 'center' },
                alignSelf: 'stretch',
                mt: 2,
                gap: { xs: '10px', md: 0 }
              }}
            >
              <Box sx={Style.checkBox}>
                <Checkbox
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                  color="primary"
                  sx={{ width: '20px' }}
                  name="agreeToTerms"
                />
                <Typography
                  sx={{
                    color: 'var(--gray-700, var(--gray-700, #323539))',

                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '-0.16px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  Agree to&nbsp;
                  <LinkWithUrl url={TERMS_OF_SERVICE}>Terms and Conditions</LinkWithUrl>
                </Typography>
              </Box>
            </Box>
            {isSignUpClicked && !checked && (
              <FormHelperText error id="agreeToTerms">
                You must agree to the Terms and Conditions.
              </FormHelperText>
            )}
            <Button
              id="btnLogin"
              type="submit"
              onClick={() => {
                setIsSignUpClicked(true)
                // handleSubmit()
              }}
              disabled={isLoading}
              variant="contained"
              sx={{
                mt: 2,
                mb: 2,
                p: 0,
                width: '100% !important',
                height: '40px',
                color: '#fff !important',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {isLoading && (
                <CircularProgress
                  size={20}
                  sx={{
                    color: 'white',
                    marginRight: '20px'
                  }}
                />
              )}
              Sign Up
            </Button>
          </Form>
        )}
      </Formik>
      <Box sx={Style.suggestionBox}>
        <Typography sx={Style.suggestion}> Already have an account?</Typography>
        <Typography onClick={onClickLogin} sx={Style.suggestionName} id="login">
          Login
        </Typography>
      </Box>
    </Box>
  )
}
const Style = {
  inputBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch'
  },
  textStyle: {
    color: 'var(--text-icons-light-base-main, #323539)',

    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.1px'
  },
  checkBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    padding: 0
  },
  suggestionBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch'
  },
  suggestion: {
    color: 'var(--gray-50, var(--gray-50, #858C95))',

    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.16px'
  },
  suggestionName: {
    color: 'var(--background-light-primary-main, #5828C4)',

    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    cursor: 'pointer'
  }
}
export default SignUpForm
