const adminPages = {
  id: 'pages',
  type: 'group',
  children: [
    {
      id: 'menu-revenue',
      title: 'Revenue',
      type: 'item',
      url: '/revenue'
    },
    {
      id: 'menu-publisher',
      title: 'Publishers',
      type: 'item',
      url: '/publisher'
    },
    {
      id: 'menu-settings',
      title: 'Settings',
      type: 'item',
      url: '/settings'
    }
  ]
}

export default adminPages
