import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '../../../assets/gear.svg'

const Logo = process.env.PUBLIC_URL + '/images/logoNew.svg'

const LogoSection = (prop: { xIcon?: any; onCloseHandler?: any }) => {
  const { xIcon = false, onCloseHandler } = prop
  const navigate = useNavigate()

  if (xIcon) {
    return (
      <Box
        onClick={() => navigate('/')}
        sx={{ maxHeight: '44px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <img src={Logo} aria-label="For Logo" alt="newLogo" height="40px" />
        <Box onClick={onCloseHandler}>
          <img src={CloseIcon} style={{ marginLeft: 'auto' }} aria-label="For Close" alt="sideMenu" />
        </Box>
      </Box>
    )
  }

  return (
    <Box onClick={() => navigate('/')} sx={{ maxHeight: '44px', paddingLeft: '1rem' }}>
      <img src={Logo} aria-label="For Logo" alt="newLogo" width="100%" height="40px" />
    </Box>
  )
}

export default LogoSection
