import PropTypes from 'prop-types'
// material-ui
import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
// project imports
import { useSelector } from 'react-redux'
import { drawerWidth } from '../../../store/constant'
import { UserType } from '../../../util/constants'
import { adminMenuItems, menuItems } from '../../menu-items'
import MenuCardLogout from './MenuCard'
import MenuList from './MenuList'
import MenuCardProfile from './MenuCardProfile'

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
  const user: any = useSelector((state: any) => state.authReducer)
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        flexShrink: { md: 0 },
        width: matchUpMd ? drawerWidth : 'auto',
        overflow: 'auto',
        gap: '40px'
      }}
    >
      <MenuCardProfile />
      <MenuList menuItem={user?.userType === UserType.ADMIN ? adminMenuItems : menuItems} />
      <MenuCardLogout />
    </Box>
  )
}

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
}

export default Sidebar
