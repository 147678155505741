import { combineReducers } from 'redux'
import apolloClient from './apolloClient'
import authReducer from './authReducer'
import settingReducer from './settingReducer'
import commonReducer from './commonReducer'
import customizationReducer from './customizationReducer'
import userReducer from './user'
import adminReducer from './admin'
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  apolloClient: apolloClient,
  authReducer: authReducer,
  userReducer: userReducer,
  commonReducer: commonReducer,
  settingReducer: settingReducer,
  customization: customizationReducer,
  adminReducer:adminReducer
})

export default reducer
