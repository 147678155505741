import { gql } from '@apollo/client'

export const queryGlobalSearch = gql`
  query globalSearch($search: String!) {
    globalSearch(search: $search) {
      campaigns
      creatives
      users
    }
  }
`
