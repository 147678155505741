import { Box, Grid, Paper, Typography } from '@mui/material'
import biddingIcon from '../../../../../assets/biddingIcon.webp'
import dollarIcon from '../../../../../assets/dollarIcon.webp'
import demandIcon from '../../../../../assets/demandIcon.webp'
// import { gsap } from 'gsap'
// import ScrollTrigger from 'gsap/ScrollTrigger'

const data = [
  {
    id: '1',
    logo: demandIcon,
    Heading: 'Exclusive Direct Demand',
    Description:
      'Crush CPM benchmarks with direct demand. Tap into high value ad deals thanks to the DOT direct sales team (included free).'
  },
  {
    id: '2',
    logo: biddingIcon,
    Heading: 'Optimize with DOT Header Bidding',
    Description:
      'Eliminate blank spots and achieve 99% fill rates with rapid loading times and powerful real-time competition.'
  },
  {
    id: '3',
    logo: dollarIcon,
    Heading: 'Monetize Your Sponsored Content',
    Description:
      'Boost your total income. We purchase your sponsored articles press releases for our advertising clients.'
  }
]

export default function OneSolution() {
  const cardStyleResolver = (i: number) => {
    switch (i) {
      case 0:
        return Style.firstCard
      case 1:
        return Style.middleCard
      case 2:
        return Style.lastCard
      default:
        return null
    }
  }

  return (
    <Box sx={{ background: 'var(--background-light-base-second, #F8F9FB)' }}>
      <Box sx={Style.mainBox}>
        <Box sx={Style.headerBox}>
          <Typography sx={Style.heading}>Multiple Income Streams. One Solution.</Typography>
          <Typography sx={Style.subTitle}>Unlock the value of your content 🔑</Typography>
        </Box>
        <Grid container>
          {data.map((user: any, i: any) => (
            <Grid item key={i} xs={12} md={4} lg={4} sx={() => cardStyleResolver(i)}>
              <Paper sx={Style.paper}>
                <Box sx={Style.logoBox}>
                  <img src={user.logo} alt="Icon" />
                </Box>
                <Box sx={Style.wrapperBox}>
                  <Typography sx={Style.cardHeading}>{user.Heading}</Typography>
                  <Typography sx={{ ...Style.userDescription }}>{user.Description}</Typography>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}

const Style = {
  mainBox: {
    display: 'flex',
    padding: { xs: '40px 16px', md: '64px 112px' },
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: { xs: '24px', md: '64px' }
  },
  headerBox: {
    display: 'flex',
    // maxWidth: "1280px",
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    alignSelf: 'stretch'
  },
  firstCard: {
    pl: 0,
    pr: { xs: 0, md: '16px', lg: '16px' },
    pb: { xs: '8px', md: 0, lg: 0 }
  },
  middleCard: {
    pt: { xs: '8px', md: 0, lg: 0 },
    pr: { xs: 0, md: '16px', lg: '16px' },
    pl: { xs: 0, md: '16px', lg: '16px' },
    pb: { xs: '8px', md: 0, lg: 0 }
  },
  lastCard: {
    pt: { xs: '8px', md: 0, lg: 0 },
    pl: { xs: 0, md: '16px', lg: '16px' },
    pr: 0,
  },
  heading: {
    color: 'var(--text-icons-light-base-main, #323539)',
    textAlign: 'center',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: '-0.48px',
    alignSelf: 'stretch'
  },
  subTitle: {
    color: 'var(--text-icons-light-base-second, #858C95)',
    textAlign: 'center',

    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '-0.18px',
    alignSelf: 'stretch'
  },
  paper: {
    padding: { xs: '16px', md: '24px 32px' },
    background: '#FFF',
    display: 'flex',
    gap: '24px',
    flexDirection: 'column',
    borderRadius: 'var(--roundness-full-outside, 24px)',
    border: '1px solid var(--stroke-light-base, #E5E5E7)',
    boxShadow: '0px 4px 8px 0px rgba(113, 128, 150, 0.08), 0px 0px 1px 0px rgba(113, 128, 150, 0.04)'
  },
  logoBox: {
    width: '56px',
    height: '56px',
    display: 'flex',
    padding: '12px var(--main-system-12-px, 12px)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'var(--roundness-round-inside, 6px)',
    background: 'var(--background-light-primary-main, #5828C4)',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.04)'
  },
  ratingBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px'
  },
  starBox: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '6px'
  },
  starIcon: {
    display: 'flex',
    padding: '3.6px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    background: '#219653'
  },
  halfStarIcon: {
    display: 'flex',
    padding: '3.6px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    background:
      'linear-gradient(90deg, #219653 0%, #219653 50%, rgba(33, 150, 83, 0.00) 50.01%, #D9D9D9 50.02%, #D9D9D9 99.98%, rgba(33, 150, 83, 0.00) 99.99%, #D9D9D9 100%)'
  },
  descriptionBox: {
    display: 'flex'
  },
  typographyStyles: {
    color: 'var(--Dark-grey, #6A6A6A)',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '26px'
  },
  userName: {
    color: 'var(--text-icons-light-base-main, #323539)',
    fontSize: { xs: '14px', md: '16px' },
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px'
  },
  userDescription: {
    color: 'var(--text-icons-light-base-second, #858C95)',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.16px'
  },
  userDesignation: {
    color: 'var(--text-icons-light-base-second, #858C95)',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.1px',
    fontSize: { xs: '12px', md: '14px' }
  },
  wrapperBox: {
    display: 'flex',
    minHeight: '164px',
    innerHeight: '164px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch'
  },
  cardHeading: {
    color: 'var(--text-icons-light-base-main, #323539)',
    paddingRight: { xs: '50px', md: '75px' },
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '30px',
    letterSpacing: '-0.22px',
    alignSelf: 'stretch'
  }
}
