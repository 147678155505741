import { useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import { useTheme } from '@mui/material/styles'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { SET_MENU } from '../../store/actions'
import { drawerWidth } from '../../store/constant'
import LogoSection from './LogoSection'
import Sidebar from './Sidebar'
import { AppHeader } from './AppHeader'

export interface Props {
  theme: any
}

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = (props: any) => {
  const theme: any = useTheme()
  const dispatch = useDispatch()
  const leftDrawerOpened = useSelector((state: any) => state?.customization?.opened)

  const matchUpXs = useMediaQuery(theme.breakpoints.up('xs'))
  const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'))
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'))

  useEffect(() => {
    if (matchUpSm === true && leftDrawerOpened) {
      dispatch({ type: SET_MENU, opened: !leftDrawerOpened })
    } else if (matchUpMd === true && leftDrawerOpened) {
      dispatch({ type: SET_MENU, opened: leftDrawerOpened })
    } else if (matchUpLg === true && leftDrawerOpened) {
      dispatch({ type: SET_MENU, opened: leftDrawerOpened })
    }
    // eslint-disable-next-line
  }, [matchUpXs, matchUpSm, matchUpMd, matchUpLg])

  const handleDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened })
  }

  return (
    <Box>
      <CssBaseline />
      <Box sx={{position:"sticky",top:"0",zIndex:"1100"}}>
      <AppHeader handleDrawerToggle={handleDrawerToggle} />
      </Box>
      <Box>
        <Outlet />
      </Box>
      {leftDrawerOpened && (
        <Drawer
          open={leftDrawerOpened}
          onClose={handleDrawerToggle}
          sx={{
            display: { xs: 'block'},
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth
            }
          }}
        >
          <Box sx={Style.mainBox}>
            <LogoSection xIcon={true} onCloseHandler={handleDrawerToggle} />
            <Sidebar />
          </Box>
        </Drawer>
      )}
    </Box>
  )
}

export default MainLayout

const Style = {
  mainBox: {
    display: 'flex',
    flex: 1,
    padding: '32px 24px',
    flexDirection: 'column',
    gap: '40px'
  }
}
