export const openFileInput = (accept: string) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = accept;
    input.click();
    return input;
};

export const openImageInput = () => openFileInput('image/*');

export const handleInputClick = (input: HTMLInputElement, callback: (file: File) => void) => {
    input.onchange = () => {
        const file = input.files?.[0];
        if (file) {
            callback(file);
        }
    };
};

export const handleImageInputClick = (id: string) => {
    const inputElement = document.getElementById(id) as HTMLInputElement;
    inputElement?.click()
}