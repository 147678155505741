import { Box, Button } from '@mui/material'
// import { useTheme } from '@mui/material/styles'
import { useState } from 'react'
import AuthModal from '../../views/modals/AuthDialog'

export default function BlueButton(props: any) {
  // const theme: any = useTheme()
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(1)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const setStepValue = (value: any) => {
    setStep(value)
  }

  return (
    <Box>
      <Button
        id="btn_signUp"
        title={props.title}
        className="animate greenButton"
        variant="contained"
        onClick={(props) => {
          setStep(2)
          handleClickOpen()
        }}
        sx={{
          display: 'flex',
          padding: '16px 24px',
          minWidth: { xs: '100%', md: '', lg: '' },
          minHeight: { xs: '40px', md: '', lg: '' },
          borderRadius: 'var(--roundness-round-inside, 4px)',
          background: '#5828C4',
          // boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.04)',
          // boxShadow: '0px 1px 2px 0px rgba(0, 13, 185, 0.96)',
          color: 'var(--text-icons-light-on-color, #FFF)',
          fontSize: '17px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '22px',
          ':hover': {
            boxShadow: '3px 3px 10px 1px rgba(0, 13, 185, 0.6)'
          }
        }}
      >
        {props.title}
        {/* <ArrowCircleRightIcon sx={{ ml: '10px' }} /> */}
      </Button>
      {open && <AuthModal open={open} handleClose={handleClose} step={step} setStepValue={setStepValue} />}
    </Box>
  )
}
