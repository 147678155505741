import PropTypes from 'prop-types'
import { forwardRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

// material-ui
import { ListItemButton, ListItemText, Typography } from '@mui/material'

// assets
import { MENU_OPEN, SET_MENU } from '../../../../../store/actions'

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }: any) => {
  const dispatch: any = useDispatch()
  const customization = useSelector((state: any) => state?.customization)
  const leftDrawerOpened = useSelector((state: any) => state?.customization?.opened)

  let itemTarget = '_self'
  if (item.target) {
    itemTarget = '_blank'
  }

  let listItemProps: any = {
    component: forwardRef((props, ref: any) => (
      <Link id={item?.id} ref={ref} {...props} to={item.url} target={itemTarget} />
    ))
  }
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget }
  }

  const itemHandler = (id: any) => {
    dispatch({ type: MENU_OPEN, id })
    // if (matchesSM) {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened })
    // }
  }

  // active menu item on page load
  useEffect(() => {
    if (item?.url && document.location.pathname.includes(item?.url)) {
      dispatch({ type: MENU_OPEN, id: item.id })
    }
    // eslint-disable-next-line
  }, [])

  const selectedMenuItem = customization?.isOpen?.findIndex((id: any) => id === item.id) > -1

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      // selected={customization?.isOpen?.findIndex((id: any) => id === item.id) > -1}
      onClick={() => itemHandler(item.id)}
      sx={{
        // Add custom styles to override the padding for smaller screens
        '@media (max-width: 767px)': {
          padding: '0px 0px!important'
        },
        '&hover': {
          backgroundColor: selectedMenuItem ? '#7f7f7f' : '#FEEEEE',
          border: selectedMenuItem ? '1px solid #7f7f7f' : '1px solid #FEEEEE'
        },
        '&.Mui-selected': {
          backgroundColor: '#7f7f7f',
          border: '1px solid #7f7f7f'
        }
      }}
    >
      <ListItemText
        sx={{
          '&hover': {
            backgroundColor: selectedMenuItem ? '#7f7f7f' : '#FEEEEE',
            border: selectedMenuItem ? '1px solid #7f7f7f' : '1px solid #FEEEEE'
          },
          '&.Mui-selected': {
            backgroundColor: '#7f7f7f',
            border: '1px solid #7f7f7f'
          }
        }}
        primary={
          <Typography
            variant={customization?.isOpen?.findIndex((id: any) => id === item.id) > -1 ? 'body1' : 'body1'}
            // color="inherit"
            sx={{
              color: 'var(--text-icons-light-base-main, #323539)',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px',
              letterSpacing: '-0.16px'
            }}
          >
            {item.title}
          </Typography>
        }
      />
    </ListItemButton>
  )
}

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number
}

export default NavItem
