import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

export const firebaseConfig = {
  apiKey: 'AIzaSyAdRxwpdhge_O9tYF8lLmy9QknNmenu8aI',
  authDomain: 'dot-publisher.firebaseapp.com',
  projectId: 'dot-publisher',
  storageBucket: 'dot-publisher.appspot.com',
  messagingSenderId: '366265751142',
  appId: '1:366265751142:web:7a86e238eb5af4d9ef7fa7',
  measurementId: 'G-43XTGT9NVB'
}

const app = initializeApp(firebaseConfig)
const appSecondary = initializeApp(firebaseConfig, 'adminCreatesUser')

export const auth = getAuth(app)
export const storage = getStorage(app)

export const authSecondary = getAuth(appSecondary)
export default app
