import { Box } from '@mui/material'

export default function Backdrop({ toggleSidebar }: any) {
  return (
    <Box
      sx={{ width: '100%', height: '100vh', position: 'fixed', top: '0', left: '0', zIndex: '1000', opacity: '0.8' }}
      className="darkBg"
      onClick={() => toggleSidebar(false)}
    ></Box>
  )
}
