import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { UserType } from '../../../util/constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { adminMenuItems, menuItems } from '../../menu-items'
import { webapp_button_clicked } from 'services/mixpanel'
import { webappButtonClicked } from 'services/mixpanel/types'

export const NavSection = () => {
  const location = useLocation()

  const [selectedNavItem, setSelectedNavItem] = useState<String>(location.pathname)
  const navigate = useNavigate()
  const user: any = useSelector((state: any) => state.authReducer)
  const navItemsMain = user?.userType === UserType.ADMIN ? adminMenuItems : menuItems
  const navItems = navItemsMain.items[0].children

  const onClickNavItemHandler = (redirectPage: string, navItem: string) => {
    const button_name = navItem?.toLowerCase()
    webapp_button_clicked({button_name})
    setSelectedNavItem(navItem)
    navigate(`${redirectPage}`)
  }

  useEffect(() => {
    setSelectedNavItem(location.pathname)
  }, [location.pathname])

  return (
    <Box sx={Style.mainBox}>
      {navItems?.map((navItem: any, index: number) => {
        return (
          <Box
            key={index}
            sx={selectedNavItem === navItem.url ? Style.navItemBoxSelected : Style.navItemBox}
            onClick={() => onClickNavItemHandler(navItem.url, navItem.title)}
          >
            <Typography sx={selectedNavItem === navItem.url ? Style.navItemTextSelected : Style.navItemText}>
              {navItem?.title}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}

const Style = {
  mainBox: {
    display: 'flex',
    ml: { md: '95px', xs: '20px' },
    cursor: 'pointer'
  },
  navItemBoxSelected: {
    display: 'flex',
    padding: '10px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: 'var(--roundness-round-inside, 6px)',
    background: 'var(--background-light-primary-second, #F1F0F6)'
  },
  navItemBox: {
    display: 'flex',
    padding: '10px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px'
  },
  navItemTextSelected: {
    color: 'var(--text-icons-light-primary, #2D146F)',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px'
  },
  navItemText: {
    color: 'var(--text-icons-light-base-second, #858C95)',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '22px'
  }
}
