import { Box } from '@mui/material'
import FusionAI from './FusionAI'
import ExistingNetwork from './ExistingNetwork'
import PayoutOptions from './PayoutOptions'
import OneSolution from './OneSolution'

export default function Features() {
  return (
    <Box component={'section'} id="Features">
      <FusionAI/>
      <ExistingNetwork/>
      <PayoutOptions/>
      <OneSolution/>
    </Box>
  )
}
