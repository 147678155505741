import { Box, Typography } from '@mui/material'
import successTick from '../../../assets/successTick.svg'
const Step5 = () => {
    return (
        <Box sx={Style.mainBox}>
            <Box sx={{
                width: "100%", display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Box sx={Style.iconBox}>
                    <img src={successTick} alt='done' width="25px" height="25px" />
                </Box>
            </Box>
            <Box sx={Style.messageBox}>
                <Typography sx={Style.heading}>All Done</Typography>
                <Typography sx={Style.description}>Congratulations, your website has been submitted successfully.  You'll receive an update within the next 3-5 business days. You can submit additional sites at the next step.</Typography>
            </Box>
        </Box>
    )
}
const Style = {
    mainBox: {
        display: "flex",
        padding: "32px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "24px",
        alignSelf: "stretch"
    },
    messageBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        alignSelf: "stretch"
    },
    iconBox: {
        display: "flex",
        width: "50px",
        height: "50px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "var(--roundness-full-inside, 200px)",
        background: "var(--base-green, #00DB96)"
    },
    heading: {
        color: "var(--text-icons-light-base-main, #323539)",
        textAlign: "center",
        
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "28px",
        letterSpacing: "-0.2px",
        alignSelf: "stretch"
    },
    description: {
        color: "var(--text-icons-light-base-second, #858C95)",
        textAlign: "center",
        
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: "-0.16px",
        alignSelf: "stretch"
    }
}
export default Step5
