import { Box, Button } from '@mui/material'
import ArrowRightIconImage from '../../assets/right.svg'
// import { BOOK_DEMO } from '../../constants/redirectLinks'
// import { redirectToNewPage } from '../../util/redirect'
import { BOOK_DEMO_POPUP} from '../../constants/redirectLinks'
// import CalendlyHelmet from '../../util/calendlyHelmet';

declare const Calendly: {
  initPopupWidget(options: { url: string }): void;
};

export default function WhiteButton(props: any) {
  
  return (
    <Box>
      <Button
        id="btn_bookdemo"
        title={props.title}
        className="animate"
        variant="contained"
        onClick={(e) => {
          e.preventDefault();
          Calendly.initPopupWidget({
            url:
              `${BOOK_DEMO_POPUP}`
          });
          return false;
        }} // Call redirectToCalendly function when the button is clicked
        sx={{
          display: 'flex',
          padding: '16px 24px',
          minWidth: { xs: '100%', md: '', lg: '' },
          minHeight: { xs: '40px', md: '', lg: '' },
          borderRadius: 'var(--roundness-round-inside, 4px)',
          background: '#fff',
          border: '1px solid var(--stroke-light-base, #E5E5E7)',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.04)',
          color: 'var(--text-icons-light-on-color, #323539)',
          fontSize: '17px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '22px',
          ':hover': {
            // color: '#fff',
            background: '#fff'
          }
        }}
      >
        {props.title}
        <img src={ArrowRightIconImage} color='white' style={{ marginLeft: '8px' }} alt='rightArrow' />
      </Button>
    </Box>
  )
}
