import * as actionTypes from '../../actionTypes'
import { updateObject } from '../../shared/utility'
interface actionType {
  type: string
  payload: any
}

const initialState = {
  publisherList: [],
  publisherDetails: [],
  publisherListAll: [],
  publisherCount: {},
  totalPublisherCount: 0,
  selectedPublisherSearch: '',
  selectedPublisherFilter: '',
  IsPublisherScreen: false
};
const adminReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case actionTypes.PUBLISHER_LIST:
      return updateObject(state, {
        loading: false,
        publisherList: action.payload
      })
    case actionTypes.PUBLISHER_DETAILS:
      return updateObject(state, {
        loading: false,
        publisherDetails: action.payload
      })
    case actionTypes.EXPORT_PUBLISHER_LIST:
      return updateObject(state, {
        loading: false,
        publisherListAll: action.payload
      })

    case actionTypes.TOTAL_PUBLISHER_COUNT:
      return updateObject(state, {
        loading: false,
        totalPublisherCount: action?.payload
      })
    case actionTypes.SET_PUBLISHER_COUNT:
      return updateObject(
        state, {
        loading: false,
        publisherCount: action.payload
      }
      )
    case actionTypes.SELECTED_PUBLISHER_FILTER:
      return updateObject(
        state, {
        loading: false,
        selectedPublisherFilter: action.payload
      }
      )
    case actionTypes.SELECTED_PUBLISHER_SEARCH:
      return updateObject(
        state, {
        loading: false,
        selectedPublisherSearch: action.payload
      }
      )
    case actionTypes.SET_IS_PUBLISHER_SCREEN:
      return updateObject(
        state, {
        loading: false,
        IsPublisherScreen: action.payload
      }
      )
    default:
      return state

  }
}

export default adminReducer