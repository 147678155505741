import { Box, Grid, Paper, Typography } from '@mui/material'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import businessCloudIcon from '../../../../assets/BusinessCloud.png'
import cryptoWidgetsIcon from '../../../../assets/CryptoMedia.png'
import webCryptoIcon from '../../../../assets/Webscrypto.png'

import trustPilotLogo from '../../../../assets/TrustPilot_4_point_5.svg'

import LogoTestimonial from '../../../../assets/Avatar.png'
import LogoTestimonial2 from '../../../../assets/Avatar2.png'
import LogoTestimonial3 from '../../../../assets/Avatar3.png'

gsap.registerPlugin(ScrollTrigger)

const data = [
  {
    id: '1',
    image: LogoTestimonial,
    logo: webCryptoIcon,
    Name: 'Nathen Shao',
    Designation: 'Head of Advertising',
    Description: '"We needed a trust-worthy partner to grow our business. DOT proved to be the best option"'
  },
  {
    id: '2',
    image: LogoTestimonial2,
    logo: businessCloudIcon,
    Name: 'Jonathan Symcox',
    Designation: 'Editor',
    Description: '"DOT supported our growth even through the bear market!"'
  },
  {
    id: '3',
    image: LogoTestimonial3,
    logo: cryptoWidgetsIcon,
    Name: 'Biciin Alexandru (Yury)',
    Designation: 'CEO',
    Description: '"We have seen tremendous earnings growth"'
  }
]

export default function Testimonials() {
  return (
    <Box sx={Style.coverBox}>
      <Box id="Testimonials" sx={Style.mainBox}>
        <Box sx={Style.headerBox}>
          <Typography sx={Style.heading}>What Our Customers Say</Typography>
          <Typography sx={Style.subTitle}>
            {'You’re going to love using DOT, but don’t just take our word for it'}
          </Typography>
        </Box>
        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {data.map((user: any, i: any) => (
            <Grid className="fade-bottom active reveal-modified" item key={i} xs={12} md={4} lg={4}>
              <Paper sx={Style.paper}>
                <Box>
                  <img
                    style={{ opacity: '0.9', mixBlendMode: 'luminosity' }}
                    src={user.logo}
                    aria-label={'For userIcon'}
                    alt="not displayUserIcon"
                    height="40px"
                  />
                </Box>
                <Box sx={{ minHeight: '85px' }}>
                  <Typography sx={Style.userDescription}>{user.Description}</Typography>
                </Box>
                <Box display={'flex'} flex={1}>
                  <Box sx={Style.userImageBox}>
                    <Box>
                      <img
                        aria-label={'For image'}
                        src={user.image}
                        alt="not displayImage"
                        height={'40px'}
                        width={'40px'}
                      />
                    </Box>
                    <Box>
                      <Typography sx={Style.userName}>{user.Name}</Typography>
                      <Typography sx={Style.userDesignation}>{user.Designation}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
        <Box sx={Style.footerBox}>
          <Box sx={Style.logoBox}>
            <img src={trustPilotLogo} style={Style.trustPilotLogo} alt='trustPilotLogo' />
          </Box>
          <Box sx={Style.ratingBox}>
            <Box sx={Style.descriptionBox}>
              <Typography style={{ fontWeight: '400' }} sx={Style.typographyStyles}>
                TrustScore <span style={{ fontWeight: '700' }}>4.5</span>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const Style = {
  coverBox: {
    background: 'var(--background-light-base-main, #FFF)',
    margin: { xs: '40px 15px', md: '64px 112px' }
  },
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: { xs: 'none', md: 'center' },
    alignItems: { xs: 'none', md: 'center' },
    gap: 'var(--main-system-64-px, 64px)',
    background: 'var(--background-light-base-main, #FFF)'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    ml: { xs: 1, md: 3, lg: 3 },
    mb: { xs: 2, md: 0, lg: 0 },
    padding: '24px',
    borderRadius: 'var(--roundness-round-outside, 12px)',
    background: '#FFF',
    boxShadow: '0px 4px 8px 0px rgba(113, 128, 150, 0.2), 0px 0px 41px 0px rgba(113, 128, 150, 0.1)',
    gap: '24px'
  },
  userImageBox: {
    display: 'flex',
    alignSelf: 'flex-end',
    gap: '10px',
    mt: '24px'
  },
  headerBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: { xs: 'none', md: 'center' },
    alignItems: { xs: 'none', md: 'center' },
    gap: '12px',
    alignSelf: 'stretch'
  },
  heading: {
    color: 'var(--text-icons-light-base-main, #323539)',
    textAlign: 'center',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: '-0.48px',
    alignSelf: 'stretch'
  },
  subTitle: {
    color: 'var(--text-icons-light-base-second, #858C95)',
    textAlign: 'center',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.16px',
    alignSelf: 'stretch'
  },
  footerBox: {
    display: 'flex',
    padding: '10px 50px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    background: '#FFF'
  },
  logoBox: {
    display: 'flex',
    alignItems: 'center'
  },
  trustPilotLogo: {
    width: '210px',
    height: '60px'
  },
  ratingBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px'
  },
  starBox: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '6px'
  },
  starIcon: {
    display: 'flex',
    padding: '3.6px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    background: '#219653'
  },
  halfStarIcon: {
    display: 'flex',
    padding: '3.6px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    background:
      'linear-gradient(90deg, #219653 0%, #219653 50%, rgba(33, 150, 83, 0.00) 50.01%, #D9D9D9 50.02%, #D9D9D9 99.98%, rgba(33, 150, 83, 0.00) 99.99%, #D9D9D9 100%)'
  },
  descriptionBox: {
    display: 'flex'
  },
  typographyStyles: {
    color: 'var(--Dark-grey, #6A6A6A)',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '26px'
  },
  userName: {
    color: 'var(--text-icons-light-base-main, #323539)',
    fontSize: { xs: '14px', md: '16px' },
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px'
  },
  userDescription: {
    color: 'var(--text-icons-light-base-main, #323539)',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px',
    letterSpacing: '-0.2px'
    // minHeight: '108px',
  },
  userDesignation: {
    color: 'var(--text-icons-light-base-second, #858C95)',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.1px',
    fontSize: { xs: '12px', md: '14px' }
  }
}
