import mixpanel from 'mixpanel-browser';
import { config } from './config';
import { Event, addNewWebsiteButtonClicked, addNewWebsiteStepsCompleted, feedbackSubmitted, homePageButtonClicked, loggedIn, newWebsiteSubmitted, onboardingImageSequenceCompleted, pageView, platform, signUpButtonClicked, signUpCompleted, signUpInitiated, userData, webappButtonClicked } from './types';

const sendMixPanelIdentifyEvent = (email: string) => {
    mixpanel.identify(email);
}
const sendMixPanelSetEvent = (data: userData) => {
    mixpanel.people.set(data)
}

const sendMixPanelTrackEvent = (event: string, data: any) => {
    try {
        data.platform = config.platform;
        data['Page Title'] = document.title;
        if (config.sendMixPanelEvents) {
            mixpanel.track(event, data);
        }
    } catch (error: any) {
        console.log('Error: FileName: mixPanel->index.ts->sendMixPanelTrackEvent');
        console.log(error.message)
    }
}

export const identifyUser = (email: string) => {
    sendMixPanelIdentifyEvent(email)
}

export const setUser = (data: userData) => {
    sendMixPanelSetEvent(data)
}

export const page_viewed = (data: pageView) => {
    sendMixPanelTrackEvent(Event.PageViewed, data)
}

export const signup_button_clicked = (data: signUpButtonClicked) => {
    sendMixPanelTrackEvent(Event.SignupButtonClicked, data)
}

export const signup_initiated = (data: signUpInitiated) => {
    sendMixPanelTrackEvent(Event.SignupInitiated, data)
}

export const signup_completed = (data: signUpCompleted) => {
    sendMixPanelTrackEvent(Event.SignupCompleted, data)
}

export const logged_in = (data: loggedIn) => {
    sendMixPanelTrackEvent(Event.LoggedIn, data)
}

export const webapp_button_clicked = (data: webappButtonClicked) => {
    sendMixPanelTrackEvent(Event.WebappButtonClicked, data)
}

export const add_new_website_button_clicked = (data: addNewWebsiteButtonClicked) => {
    sendMixPanelTrackEvent(Event.AddNewWebsiteButtonClicked, data)
}

export const onboarding_image_sequence_completed = (data: onboardingImageSequenceCompleted) => {
    sendMixPanelTrackEvent(Event.OnboardingImageSequenceCompleted, data)
}

export const add_new_website_step1_completed = (data: addNewWebsiteStepsCompleted) => {
    sendMixPanelTrackEvent(Event.AddNewWebsiteStep1Completed, data)
}

export const add_new_website_step2_completed = (data: addNewWebsiteStepsCompleted) => {
    sendMixPanelTrackEvent(Event.AddNewWebsiteStep2Completed, data)
}

export const add_new_website_step3_completed = (data: addNewWebsiteStepsCompleted) => {
    sendMixPanelTrackEvent(Event.AddNewWebsiteStep3Completed, data)
}

export const add_new_website_step4_completed = (data: addNewWebsiteStepsCompleted) => {
    sendMixPanelTrackEvent(Event.AddNewWebsiteStep3Completed, data)
}


export const analytics_screenshot_upload_initiated = (data: platform) => {
    sendMixPanelTrackEvent(Event.AnalyticsScreenShotUploadInitiated, data)
}

export const analytics_screenshot_upload_completed = (data: platform) => {
    sendMixPanelTrackEvent(Event.AnalyticsScreenShotUploadCompleted, data)
}

export const new_website_submited = (data: newWebsiteSubmitted) => {
    sendMixPanelTrackEvent(Event.NewWebsiteSubmitted, data)
}

export const homepage_button_clicked = (data: homePageButtonClicked) => {
    sendMixPanelTrackEvent(Event.HomepageButtonClicked, data)
}

export const feedback_submitted = (data: feedbackSubmitted) => {
    sendMixPanelTrackEvent(Event.FeedbackSubmitted, data)
}