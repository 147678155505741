// action - state management
import * as actionTypes from '../actions/customization'
import { DATA_LOADING_END, DATA_LOADING_START, ERROR_MSG, SUCCESS_MSG, WARNING_MSG } from '../actionTypes'
import { updateObject } from '../shared/utility'

export const initialState = {
  isLoading: false,
  isOpen: [], // for active default menu
  fontFamily: `'Inter', sans-serif`,
  opened: false,
  isError: false,
  notificationMsg: '',
  severity: '' //error,warning,info,success
}

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action: any) => {
  let id
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      id = action.id
      return {
        ...state,
        isOpen: [id],
        isError: false
      }
    case actionTypes.SET_MENU:
      return {
        ...state,
        opened: action.opened
      }

    case DATA_LOADING_START:
      return {
        ...state,
        isLoading: true
      }
    case DATA_LOADING_END:
      return {
        ...state,
        isLoading: false
      }
    case SUCCESS_MSG:
      return {
        ...state,
        isError: false,
        notificationMsg: action.data,
        severity: 'success'
      }
    case ERROR_MSG:
      return updateObject(state, {
        ...state,
        isError: true,
        notificationMsg: action.data,
        severity: 'error'
      })
    case WARNING_MSG:
      return updateObject(state, {
        ...state,
        isError: true,
        notificationMsg: action.data,
        severity: 'warning'
      })
    default:
      return state
  }
}

export default customizationReducer
