declare global {
    interface Window {
        dataLayer: any[];
    }
}

const sendGtmEvents = process.env.REACT_APP_SEND_GTM_EVENTS === 'true' ? true : false

export const sendNewRegistrationEventToGTM = (data: any) => {
    window.dataLayer.push({
        event: 'userRegistered',
        eventValue: data?.userEmail
    });
}

export const sendNewPaymentEventToGTM = (data: any) => {
    window.dataLayer.push({
        event: 'userPayment',
        eventValue: data?.userEmail
    })
}

export const sendNewPublisherRegisteredEventToGTM = (data: any) => {
    if (sendGtmEvents) {
        window.dataLayer.push({
            event: 'publisherRegistered',
            eventValue: data?.userEmail
        })
    }
}

export const sendNewWebsiteSubmitedEventToGTM = (data: any) => {
    if (sendGtmEvents) {
        window.dataLayer.push({
            event: 'newWebsiteSubmited',
            eventValue: data?.domain_name
        })
    }
}

