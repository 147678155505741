import TrustPilotImage from '../../../../assets/trustpilot.svg'
import StarImage from '../../../../assets/star.svg'
import { Box, Typography } from '@mui/material'

// const GreenStar = `${process.env.PUBLIC_URL}/images/greenstar.webp`

// const Star = ({ color }: any) => <span style={{ color }}>&#9733;</span>

const TrustpilotRating = (props: any) => {
  return (
    <Box className="trustpilot" id="trustPilotLogo" sx={{ display: { xs: 'none', md: 'flex', lg: ' flex' }, gap: 1, alignItems: 'center' }}>
      <img src={TrustPilotImage} aria-label="For GreenStar" alt="GreenStar" width="155px" height="38" />
      <img
        src={StarImage}
        aria-label="For GreenStar"
        alt="GreenStar"
        width="120px"
        height="20"
        style={{ marginTop: '6px' }}
      />

      <Typography
        sx={{
          color: 'var(--blue-gray-700, #334155)',
          fontSize: '20.135px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: 'normal',
          mt: '8px',
          ml: '4px'
        }}
        color={props.ratingColor}
      >
        4.5 Stars
      </Typography>
    </Box>
  )
}

export default TrustpilotRating
